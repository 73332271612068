import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Col, InputNumber, Modal, Row, Space } from "antd";
import Webcam from "react-webcam";
import { rid } from "../../../../helpers";
import style from "./style.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor'
import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import { useEventListener } from "../../../../hooks";
import { ConfirmModal, Select } from "../../../../components";

var _defaultTimer = 2;

const WebcamCapture = forwardRef(
  ({ title = i18n.t(languageKeys.chup_anh), className, width = "100%", height = "100%", onTakePhoto = () => {} }, ref) => {

    const webcamRef = useRef(null);
    const imageEditorRef = useRef(null);
    const confirmRef = useRef(null);

    const [visible, setVisible] = useState(false); // đống mở modal chụp ảnh
    const [visibleCam, setVisibleCam] = useState(false); // mở camera
    const [currentPicture, setCurrentPicture] = useState(null); // ảnh vừa chụp
    const [listCurrentImage, setListCurrentImage] = useState([]); // danh sách ảnh đã chụp
    const [visibleEdit, setVisibleEdit] = useState(false); // hiển thị edit 
    const [visibleCurrentImg, setVisibleCurrentImg] = useState(false); // hiển thị ảnh vừa chụp 
    const [currentIndex, setCurrentIndex] = useState(null);
    const [timer, setTimer] = useState(_defaultTimer);
    const [isRunning, setIsRunning] = useState(false);
    const [changeTimer, setChangeTimer] = useState(false);
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);

    const savedDeviceId = localStorage.getItem('webcamID_DeepcareRIS')

    useImperativeHandle(ref, () => ({
      open: () => {
        setVisible(true);
        !devices[0] && navigator.mediaDevices.enumerateDevices().then(handleDevices);
      },
      takePhoto: capture,
      close: handleClose,
    }));

    useEffect(() => {
      if (visible) setVisibleCam(true);
      if (!visible) setCurrentPicture(null);

      return () => {
        setCurrentPicture(null);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    async function handleClose() {
      await setVisibleCam(false);
      await setVisible(false);
      setListCurrentImage([]);
      setCurrentPicture();
      setVisibleCam(true);
      setVisibleEdit(false);
      setVisibleCurrentImg(false)
    }
    const
      capture = async () => {
        if (!visibleCam) {
          setVisibleCam(true);
          visibleEdit && setVisibleEdit(false);
          visibleCurrentImg && setVisibleCurrentImg(false)
        } else {
          if (listCurrentImage.length == 8) {
            // notification.error({message : i18n.t(languageKeys.so_luong_anh_toi_da)});
            const tempImg = await webcamRef.current.getScreenshot();
            confirmRef.current.open(tempImg)
            return
          }
          const imageSrc = await webcamRef.current?.getScreenshot();
          setListCurrentImage(prev => prev.concat(imageSrc))
        }
      };
    const handleCloseConfirmModal = () => {
      confirmRef.current.close()
    }
    const submitPicture = () => {
      onTakePhoto(listCurrentImage)

    };
    const handleForcePushImage = async () => {
      const tempImg = await webcamRef.current.getScreenshot();
      setListCurrentImage((currentList) => {
        let newData = [...currentList];
        newData.pop();
        newData.unshift(tempImg);
        return newData;
      })
      confirmRef.current.close();
    }

    const handleSaveImage = () => {
      const canvas = document.querySelector(".lower-canvas");
      const imageData = canvas.toDataURL("image/jpeg");
      setCurrentPicture(imageData);
      setVisibleEdit(false);
      setListCurrentImage(prev => {
        return prev.map((item, index) => {
          if (index == currentIndex) {
            return imageData
          }
          return item
        })
      })
      setVisibleCam(true);
    }


    const pickImageCurrentList = (image, index) => {
      setCurrentPicture(image);
      setCurrentIndex(index)
      setVisibleCurrentImg(true);
      setVisibleEdit(false);
      setVisibleCam(false);
    }

    const removeImage = (data, index) => {
      setListCurrentImage(prev => {
        return prev.filter((item, index) => item !== data);
      })
      if (currentPicture === data) {
        currentPicture && setVisibleCurrentImg(false);
        setVisibleCam(true)
      }
    }

    const handleCountDown = () => {
      isRunning ? setIsRunning(false) : setIsRunning(true)
    }

    useEffect(() => {
      let interval = null;
      if (!changeTimer) {
        if (isRunning) {
          interval = setInterval(() => {
            if (timer > 0) {
              setTimer(timer => timer - 1);
            }
            if (timer == 0) {
              if (listCurrentImage.length == 6) {
                setIsRunning(false);
                setTimer(_defaultTimer)
              } else {
                capture()
              }
              setTimer(_defaultTimer)
            }

          }, 1000);
        } else {
          clearInterval(interval);
          setTimer(_defaultTimer)
        }
      } else {
        setChangeTimer(false)
      }
      return () => clearInterval(interval);
    }, [isRunning, timer, changeTimer]);

    const keydownEvent = (event) => {
      if (event.key === "Enter" || (event.keyCode == 123 && event.ctrlKey)  ) {
        event.stopPropagation();
        event.preventDefault();
        if (visibleCam) {
          capture()
        }
      }
    };
    useEventListener("keydown", keydownEvent, window.document, visible);

    const handleDevices = React.useCallback(
      mediaDevices => {
        const list = mediaDevices.filter(({ kind }) => kind === "videoinput")
        setDevices(list)
        setDeviceId(list.find(i => i.deviceId === savedDeviceId) ? savedDeviceId : list[0]?.deviceId)
      },
      [setDevices]
    );

    return (
      <Modal
        className={className}
        title={
          <Space>
            <h3>{i18n.t(languageKeys.chup_anh)}</h3>
            <Select
              dataSource={devices || []}
              valueKey='deviceId'
              titleKey='label'
              value={deviceId}
              onChange={(id) => {
                setDeviceId(id)
                localStorage.setItem('webcamID_DeepcareRIS', id)
              }}
            />
          </Space>
        }
        open={visible}
        width={1200}
        onCancel={handleClose}
        style={{ overflow: "hidden" }}
        footer={null}
        centered
      >
        <div className={style["modal-container"]}>

          <div className={style["video-container"]}>
            {visibleCam && (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                height="100%"
                videoConstraints={{ width: 1280, deviceId }}
                width="100%"
              // onUserMedia={(e) => handleStopMedia(e)}
              />
            )}

            {
              visibleCurrentImg && (
                <div className={style["preview-imagess"]} style={{ marginRight: 10, backgroundColor: "red" }}>
                  <img src={currentPicture} />
                </div>
              )
            }

            {
              visibleEdit && (
                <div>
                  <ImageEditor
                    ref={imageEditorRef}
                    includeUI={{
                      loadImage: {
                        path: currentPicture,
                        name: 'SampleImage',
                      },
                      uiSize: {
                        width: '100%',
                        height: '480px',
                      },
                      menuBarPosition: 'bottom',
                    }}
                    cssMaxHeight={600}
                    cssMaxWidth={600}
                    selectionStyle={{
                      cornerSize: 20,
                      rotatingPointOffset: 70,
                    }}
                    usageStatistics={true}

                  />
                </div>
              )
            }
          </div>

          <div className={style["preview-image"]}>
            <Row gutter={[10, 5]} >
              {listCurrentImage.map((item, index) => (
                <Col flex={"48%"} key={index} onClick={() => pickImageCurrentList(item, index)} className={style["item-img-preview"]}>
                  <img src={item} style={{ width: "100%", height: 130, resize: "cover", borderRadius: 10 }} />
                  <div className={style["icon-delete"]} onClick={(e) => {
                    e.stopPropagation();
                    removeImage(item, index)
                  }}>
                    <CloseOutlined style={{ fontSize: 8, transform: "translateY(-2px)" }} />
                  </div>
                </Col>
              ))}
              {
                listCurrentImage.length < 8 && (Array(8 - listCurrentImage.length).fill(null).map((item, idex) => (
                  <Col
                    flex={"48%"}
                    key={rid()}
                    className={style["item-img-preview"]}
                    style={{ height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center' }}

                  >
                    <CameraOutlined style={{ fontSize: 15, transform: "translateY(-2px)" }} />
                  </Col>
                )))
              }
            </Row>
          </div>

        </div>
        <Row gutter={10} justify="center" style={{ marginTop: 15 }} align="middle">
          <Col className={style['text-timer']}>
            <span>{i18n.t(languageKeys.thoi_gian_dem_lui)}</span>
          </Col>
          <Col>
            <InputNumber
              value={timer}
              style={{ width: 50 }}
              max={30}
              min={0}
              onChange={(e) => {
                _defaultTimer = e;
                !changeTimer && setChangeTimer(true);

              }}
              controls={false}
            />
          </Col>
          <Col className={style['text-timer']}>
            <span>(s)</span>
          </Col>
          <Col>
            <Button type="primary" onClick={handleCountDown} style={{ width: 120 }}>
              {isRunning ? i18n.t(languageKeys.action_dung) : i18n.t(languageKeys.chup_tu_dong)}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={capture}>
              {i18n.t(languageKeys.chup_anh)} (Enter)
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setVisibleEdit(true);
                setVisibleCurrentImg(false);
                setVisibleCam(false)
              }}
              disabled={visibleCam || visibleEdit}
            >
              {i18n.t(languageKeys.common_Chinh_sua)}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                handleSaveImage()
              }}
              disabled={!visibleEdit}
            >
              {i18n.t(languageKeys.save_image)}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={submitPicture} disabled={listCurrentImage.length < 1}>
              {i18n.t(languageKeys.common_Chon)}
            </Button>
          </Col>
          <Col>
            <Button type="danger" ghost onClick={handleClose}>
              {i18n.t(languageKeys.common_Huy)}
            </Button>
          </Col>
        </Row>
        <ConfirmModal
          ref={confirmRef}
          content="Bạn đã chụp đủ 06s ảnh, bạn có tiếp tục muốn chụp và thay thế các ảnh đã chụp không?"
          danger={true}
          onOk={handleForcePushImage}
          onCancel={() => {
            handleCloseConfirmModal()
          }}
        />

      </Modal>
    );
  }
);
export default memo(WebcamCapture);
