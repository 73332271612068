import { CameraOutlined, CloseOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Drawer, Form, Input, Layout, notification, Popover, Row, Select as SelectAntd } from "antd";
import { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from "../../../components/Select/Select";
import i18n, { languageKeys } from "../../../i18n";
import style from "./formStyle.module.less";
import {
  common_post,
  convertDateToValue,
  getBase64,
  getSizePayload,
  HLog,
  isEmptyObject,
  removeTags,
  rid,
  getBase64FromUrlWithCompression,
  common_get,
  uploadImageBase64,
  getPrivateImageLink,
} from "../../../helpers";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import SiderFormNhap from "./components/SiderFormNhap";
import WebcamCapture from "../components/CapturingCamera/WebcamCapture";
import FormRISContent from "./components/FormRISContent";
import moment from "moment";
import _, { debounce } from "lodash";
import { useEventListener, useFakeSyncronousState } from "../../../hooks";
import ModalPDF from "../../../components/Modal/ModalPDF";
import { apis, keys } from "../../../constants";
import { TraKQContext, trang_thai_th, trang_thai_tra } from "./TraKetQuaCdha";
import { apiPhieuTra } from "./services";
import { resetRepo, setIsChange } from "../../../ducks/slices/snapshotSlice";
import { dm_may_th, widthDrawerKeys } from "../../../constants/keys";
import SiderAnh from "./components/SiderAnh";
import ModalImportImage from "../components/ModalImportImage/ModalImportImage";
import { ConfirmModal, FormDrawer } from "../../../components";
import HeaderElement from "../../QuanLyTiepDon/ModalTiepDon.new/components/HeaderElement/HeaderElement";
import ModalEditImage from "../components/ModalEditImage/ModalEditImage";
import { TooltipButton } from "components/Tooltip/CustomTooltip";
import { Attachment, NewBackIcon, Delete, FileCheck } from "assets/svg";
import { nanoid } from "@reduxjs/toolkit";
import QlyVatTuRIS from "./components/QlyVatTuRIS";
import { PhanLoaiSieuAmRIS, PhanLoaiXQuangRIS } from "constants/data";
import { KhamBenhActions } from "../../../ducks/slices/khamBenhSlice";



const { Content } = Layout;
const { Item } = Form;

const FormNhapKetQua = forwardRef(
  (
    {
      phieu = {},
      dsDichvu = [],
      doAfterSubmit = () => {},
      patientInfo = {},
      onCloseProps = () => {},
      doAfterDestroy = () => {},
      handleChuyenTrangThaiDichVu = async () => {},
      disableTraKQ,
      disableHuyKQ,
      disableIn,
      extractContext,
      disabledHuyTH,
    },
    ref
  ) => {
    const [form] = Form.useForm();
    const dsBacSiRis = useSelector((state) => state.khamBenh.dsBacSiRis);

    const { selectedPhong, selectedPhieu } = useContext(extractContext ? extractContext : TraKQContext);
    const contentRef = useRef();
    const userProfile = useSelector((state) => state.auth.user);
    const imageInputRef = useRef();

    const webcamRef = useRef();
    const pdfRef = useRef();
    const importRef = useRef();
    const confirmRemoveAllRef = useRef();
    const editImageRef = useRef();
    const vattuRef = useRef();

    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [arrImgPrev, setArrImgPrev] = useState([]);
    const [currService, setCurrService] = useState();
    const [currentListDv, setCurrentListDv] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dsMay, setDsMay] = useState([]);
    const [selectedMay, setSelectedMay] = useState();
    const [dsMau, setDsMau] = useState([]);
    const [selectedMau, setSelectedMau] = useState();
    const [loadingDsMay, setLoadingDsMay] = useState(false);
    const [returnHistory, setReturnHistory] = useState(false);
    const { getState, setState } = useFakeSyncronousState();
    const [openTTPhieu, setOpenTTPhieu] = useState(true);
    const dispatch = useDispatch();
    const dataLocal = useSelector((state) => state.data);
    const [seletedImage, setSeletedImage] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState();
    const [listImageUrl, setListImageUrl] = useState();
    const [resultUrl, setResultUrl] = useState();
    const [isConnectVin, setIsConnectVin] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [deleteFileList, setDeleteFileList] = useState([]);
    const [phanLoai, setPhanLoai] = useState();
    const [showElectronicSign, setShowElectronicSign] = useState(false);
    const [inPacs, setInPacs] = useState(false);

    const fileRef = useRef();

    const importListImageFromUrl = async (url_arr) => {
      const formatedArr = Array.isArray(url_arr) ? url_arr : JSON.parse(url_arr);
      try {
        const arr_base64 = await Promise.all(formatedArr.map(imgUrl => getBase64FromUrlWithCompression(imgUrl)))
        const list_img = arr_base64.map((item, idx) => ({
          key: rid(),
          thumbnail: item,
          url: formatedArr[idx] || ''
        }))
        setArrImgPrev(prev => prev.concat(list_img))
      } catch (e) {
        HLog(e, 'error')
      }
    }

    useImperativeHandle(ref, () => ({
      onOpen: handleOpenForm,
      onClose: handleClose,
      setCurrService,
      visible,
    }));

    const handleOpenForm = async (record) => {
      if (!record) {
        setCurrService(() => {
          let dichvu = dsDichvu.find((item) => (item.TRANG_THAI_KQ) === trang_thai_tra.CHO_KET_QUA);
          form.setFields([
            {
              name: 'GHI_CHU',
              value: dichvu?.GHI_CHU,
            },
            {
              name: 'NGUOI_THUC_HIEN_ID',
              value: userProfile?.NHANSU_ID,
            },
            {
              name: 'NGUOI_TRA_KQ_ID',
              value: userProfile?.NHANSU_ID,
            },
          ])
          return dichvu;
        });
      } else if (record.DICH_VU_ID) {
        setSelectedRecord(record);
        if (!!record?.LINK_VIEW) setListImageUrl(record?.LINK_VIEW);
        if (!!record?.IMAGE_URL) setResultUrl(Array?.isArray(record?.IMAGE_URL) ? record?.IMAGE_URL[0] : (JSON.parse(record?.IMAGE_URL)[0] || ''));

        if (record?.IS_CONNECT_VIN === false) setIsConnectVin(false)
        if (!!record?.IMAGE_URL_ARR) {
          importListImageFromUrl(record?.IMAGE_URL_ARR)
        }
        if (!!record?.ANH_RIS && record?.ANH_RIS?.length > 0) {
          getListImage2(record?.ANH_RIS)
        } else {
          setSeletedImage([])
        }

        setCurrService(() => {
          let dichvu = dsDichvu.find((item, index) => item.DICH_VU_ID === record.DICH_VU_ID);
          form.setFields([
            {
              name: 'GHI_CHU',
              value: dichvu?.GHI_CHU,
            },
            {
              name: 'NGUOI_THUC_HIEN_ID',
              value: record?.NGUOI_THUC_HIEN_ID || userProfile?.NHANSU_ID,
            },
            {
              name: 'NGUOI_TRA_KQ_ID',
              value: record?.NGUOI_THUC_HIEN_ID || userProfile?.NHANSU_ID,
            },
          ])
          return dichvu;
        });
      }
      setVisible(true);
    };

    const handleClose = (props) => {
      setOpenTTPhieu(true);
      dispatch(setIsChange(false));
      setVisible(false);
      setSelectedRecord();
      setListImageUrl('');
      setResultUrl('');
      setIsConnectVin(true);
      setShowElectronicSign(false);
      onCloseProps(props);
    };

    // const isAllowQuyenRis = useMemo(() => {
    //   let isAllowHuyTra = false;
    //   let isAllowTraPhieu = false;

    //   if (userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri) {
    //     isAllowHuyTra = true;
    //     isAllowTraPhieu = true;
    //   } else if (!!userProfile.QUYEN) {
    //     let dsQuyen = [];
    //     if (isJsonString(userProfile.QUYEN)) {
    //       dsQuyen = JSON.parse(userProfile.QUYEN);
    //     } else {
    //       dsQuyen = userProfile.QUYEN;
    //     }
    //     isAllowHuyTra = dsQuyen.some((quyen) => quyen.includes(`${featureKeys.tra_ket_qua_cdha}.${featureKeys.huy_tra_phieu_cdha}`));
    //     isAllowTraPhieu = dsQuyen.some((quyen) => quyen.includes(`${featureKeys.tra_ket_qua_cdha}.${featureKeys.tra_ket_qua}`));
    //   }
    //   return { isAllowHuyTra, isAllowTraPhieu };
    // }, [userProfile]);

    // xử lý phím tắt
    const keydownEvent = (event) => {
      if (visible && currService) {
        if (event.ctrlKey && event.key === "l") {
          event.stopPropagation();
          event.preventDefault();
          setState(true);
          setInPacs(false);
          form.submit();
        }
        if (event.ctrlKey && event.key === "p") {
          event.stopPropagation();
          event.preventDefault();
          setState(true);
          setInPacs(true);
          form.submit();
        }
        if (event.ctrlKey && event.key === "k") {
          if (!disableSubmit()) {
            event.stopPropagation();
            event.preventDefault();
            setState(false);
            setInPacs(false);
            form.submit();
          }
        }
        if (currService?.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA) {

          if (event.ctrlKey && event.key === "r") {
            if (isConnectVin) return;
            event.stopPropagation();
            event.preventDefault();
            handleResetForm();
          }
          if (currService?.TRANG_THAI_TH !== trang_thai_th.CHO_THUC_HIEN) {
            if (event.ctrlKey && event.key === "d") {
              event.stopPropagation();
              event.preventDefault();
              handleHuyThucHien();
            }
          }
        }

        if (currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA) {
          if (event.ctrlKey && event.key === "i") {
            event.stopPropagation();
            event.preventDefault();
            onPressPrint();
          }
          if (event.ctrlKey && event.key === "y") {
            event.stopPropagation();
            event.preventDefault();
            onPressPrint(true);
          }
          if (event.ctrlKey && event.key === "d") {
            event.stopPropagation();
            event.preventDefault();
            handleHuyTra();
          }
        } else {
          return;
        }
      }
    };

    // lấy danh sách máy theo loại máy và tự động chọn máy đầu tiên trong danh sách
    const layDsMay = async (search_string = "") => {
      try {
        let body = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          search_string,
          LOAI_MAY: dm_may_th.CDHA,
          page: 1,
          limit: 25,
        };
        let res = await common_post(apis.dm_layds_may_hieu_luc, body, false);
        if (res && res.status === "OK") {
          let newDsMay = res.result.map((item) => ({ ...item, key: rid() }));
          setDsMay(newDsMay);
          if (!!newDsMay.length) {
            setSelectedMay(newDsMay[0]);
          } else {
            setSelectedMay();
          }
        }
      } catch (error) {}
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const onSearchDsMay = useCallback(debounce(layDsMay, 1500), []);

    // lấy mẫu mô tả theo dịch vụ + tự động chọn mẫu đầu tiên
    const layDsMauMoTa = async (search_string = "", DICH_VU_ID, mau_chi_dinh_id = null, isAutoFill = true) => {
      try {
        setLoadingDsMay(true);
        let body = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          DICH_VU_ID,
          search_string,
          page: 1,
          limit: keys.limit,
        };
        let res = await common_post(apis.ris_lay_ds_mau_mo_ta, body, false);
        if (res && res.status === "OK") {
          let newDsMau = res.result.map((item) => ({ ...item, key: rid() }));
          setDsMau(newDsMau);
          if (newDsMau.length) {
            if (!!mau_chi_dinh_id) {
              // Nếu dịch vụ đã từng trả và có mẫu chỉ định => chọn mẫu chỉ định đó
              let mau = newDsMau.find((item) => item.ID === mau_chi_dinh_id);
              new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
                setSelectedMau(mau);
                if (contentRef.current) {
                  if (!!mau) {
                    form.setFields([
                      {
                        name: "CHON_MAU",
                        value: mau.TEN_MAU,
                      },
                    ]);
                  } else {
                    form.resetFields(["CHON_MAU"]);
                  }
                }
                setLoadingDsMay(false);
              });
            } else {
              if (isAutoFill) {
                setSelectedMau(newDsMau[0]);
                if (contentRef.current) {
                  contentRef.current.setDescription(newDsMau[0]?.MO_TA);
                  contentRef.current.setKetLuan(newDsMau[0]?.KET_LUAN);
                  form.setFields([
                    {
                      name: "CHON_MAU",
                      value: newDsMau[0].TEN_MAU,
                    },
                  ]);
                }
              }
              setLoadingDsMay(false);
            }
          } else {
            setLoadingDsMay(false);
            setSelectedMau();
            form.resetFields(["CHON_MAU"]);
          }
        }
        dispatch(setIsChange(false));
      } catch (error) {
        HLog("loi lay ds phieu mau", error);
        setLoadingDsMay(false);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchDsMau = useCallback(debounce(layDsMauMoTa, 1000), []);

    // click vào dịch vụ đã trả kết quả
    const layChiTietDichvuDaTra = async (PHIEU_TRA_ID) => {
      try {
        let body = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          PHIEU_TRA_ID,
        };
        let res = await common_post(apis.ris_lay_chi_tiet_phieu_tra, body, false);

        if (res && res.status === "OK") {
          let { result } = res;
          setFileList(result[0].FILE?.map(obj => ({ ...obj, TEN_FILE: obj.ORIGINAL_NAME, FILE_ID: obj.ID, isOld: true })))
          form.setFields([
            {
              name: fieldKetQua.NGAY_TRA_KQ,
              value: convertDateToValue(result[0]["NGAY_TRA_KET_QUA"]),
            },
            {
              name: 'NGUOI_TRA_KQ_ID',
              value: result[0]["NGUOI_TRA_KQ_ID"],
            },
            {
              name: 'NGUOI_THUC_HIEN_ID',
              value: result[0]["NGUOI_THUC_HIEN_ID"],
            },
            {
              name: 'GHI_CHU',
              value: result[0]["GHI_CHU"],
            },
          ]);
          // if (dsMau.length) {
          //   let mau_mo_ta = dsMau.find((item) => item.ID === result[0].MAU_MO_TA_ID);
          //   setSelectedMau(!!mau_mo_ta ? JSON.stringify(mau_mo_ta) : null);
          // }
          setSelectedMay({ TEN_MAY: result[0]["TEN_MAY"], ID: result[0].MAY_ID ? result[0].MAY_ID : null });
          if (!!result[0]?.HIEN_THI_CHU_KY) setShowElectronicSign(!!1);
          if (result[0].MAU_MO_TA_ID) {
            let hasModel = dsMau.find((mau) => mau.ID === result[0].MAU_MO_TA_ID);
            if (!!hasModel) {
              setSelectedMau(hasModel);
              form.setFields([
                {
                  name: "CHON_MAU",
                  value: hasModel.TEN_MAU,
                },
              ]);
            } else {
              await layDsMauMoTa("", currService?.DICH_VU_ID, result[0].MAU_MO_TA_ID);
            }
          }

          // setArrImgPrev(() => {
          //   let newArrImg = JSON.parse(result[0]["ANH_ID"]);
          //   newArrImg = newArrImg.map((item) => {
          //     let newImg = {
          //       id: item,
          //       key: rid(),
          //       thumbnail: getImageFromSever(item),
          //     };
          //     return newImg;
          //   });
          //   return newArrImg;
          // });

          if (!!result[0]?.ANH_RIS && result[0]?.ANH_RIS?.length > 0) {
            getListImage2(result[0]?.ANH_RIS)
          } else {
            setSeletedImage([])
          }
          contentRef.current && contentRef.current.setDescription(result[0].MO_TA);
          contentRef.current && contentRef.current.setKetLuan(result[0].KET_LUAN);
          contentRef.current && contentRef.current.setKyThuat(result[0].KY_THUAT);
          contentRef.current && contentRef.current.setPhanLoai(result[0].PHAN_LOAI_BENH_TAT);
        }
      } catch (error) {
        HLog("Lỗi lấy chi tiết dịch vụ đã trả: ", error);
      }
    };

    const handleFilter = useCallback(
      (search_string) => {
        let trimString = search_string.trim().toLowerCase();
        let newList = dsDichvu.filter((dichvu) => {
          let ten_dich_vu = dichvu.TEN_DICHVU.toLowerCase();

          return ten_dich_vu.includes(trimString);
        });

        setCurrentListDv(newList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [dsDichvu]
    );
    const handleRemoveFile = (key) => {
      const image = seletedImage.find((item) => item.key === key);

      setSeletedImage((prevArr) => {
        let newArr = prevArr.filter((item) => item.key !== key);

        return newArr;
      });
      let string = contentRef.current?.description || "";
      let result = string.match(/<img .*?src=['"](.*?)['"].*?>/g);
      if (result && result.length > 0) {
        // const itemRemove = result.find((el) => el.includes(image.thumbnail) && el.includes(`id="${image.key}"`));
        // string = string.replace(itemRemove, "&lt;image&gt;");

        const regex = new RegExp(`(<img .*?src=['"](.*?)['"].*?>)`, "g");
        string = string.replace(regex, (match, imgTag) => {
          if (imgTag.includes(image.thumbnail) && imgTag.includes(`id="${image.key}"`)) {
            return "&lt;image&gt;";
          }
          return match; // Giữ nguyên phần tử nếu không thỏa mãn điều kiện
        });
        contentRef.current.setDescription(string);
      }
    };

    // action button chèn ảnh
    const handleImage = async (info) => {
      dispatch(setIsChange(true));

      //config length image in hospital config
      if (seletedImage.length === 8) {
        notification.error({ message: i18n.t(languageKeys.so_luong_anh_toi_da), placement: "bottomLeft" });
        return;
      }
      let file = info.target.files[0];

      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        notification.error({
          message: i18n.t(languageKeys.noti_Kich_thuoc_anh_be_hon_1mb),
          placement: "bottomLeft",
        });
        return;
      }

      const isImage = file.type.indexOf("image/") === 0;

      if (!isImage) {
        notification.error({ message: i18n.t(languageKeys.noti_Chi_duoc_upload_anh), placement: "bottomLeft" });
        return;
      } else {
        await getBase64(file)
          .then((image64) =>
            setSeletedImage((prevArr) => {
              const buffer = Buffer.from(image64.substring(image64.indexOf(",") + 1));

              console.log("Byte length: " + buffer.length);
              console.log("MB: " + buffer.length / 1e6);
              let newImg = {
                key: rid(),
                thumbnail: image64,
              };
              return [...prevArr, newImg];
            })
          )
          .then(() => imageInputRef.current && (imageInputRef.current.value = ""))
          .catch((err) => console.error(err));
      }
    };

    // xử lý ảnh base64 sau khi chụp (action button chụp ảnh)
    const handleGetPhotoBase64 = useCallback(
      async (arr_img) => {
        dispatch(setIsChange(true));
        let array_after_check = [];
        for (let i = 0; i < arr_img.length; i++) {
          const image64 = arr_img[i];
          let stringLength = image64.length - ("data:image/png;base64,".length || "data:image/jpeg;base64,".length);
          let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
          let isLt1M = sizeInBytes / 1024 / 1024 < 1;
          if (!isLt1M) {
            notification.error({
              message: i18n.t(languageKeys.noti_Kich_thuoc_anh_be_hon_1mb),
              placement: "bottomLeft",
            });
            return;
          }
          let newImg = {
            key: rid(),
            thumbnail: image64,
          };
          array_after_check.push(newImg);
        }

        if (seletedImage.length < 8) {
          let total_current_img = seletedImage.length;
          let total_imported = array_after_check.length;
          let total_image_free = 8 - total_current_img;
          let add_array;
          if (total_imported <= total_image_free) {
            add_array = [...array_after_check];
          } else {
            add_array = [...array_after_check].splice(0, total_image_free);
          }
          setSeletedImage((prev) => prev.concat(add_array));
          fillImage(add_array)
        } else {
          notification.error({ message: "Số lượng ảnh tối đa 8" });
        }
        webcamRef.current.close();
      },
      [webcamRef, seletedImage]
    );
    // dieu kien tat 2 button anh
    const disableUpload = () => {
      let check = false;
      if (!currService || seletedImage.length >= 8 || currService?.TRANG_THAI_KQ !== trang_thai_tra.CHO_KET_QUA) {
        check = true;
      } else {
        check = false;
      }
      return check;
    };

    // dieu kien tat button luu
    const disableSubmit = () => {
      let check = false;

      if (!currService || currService?.TRANG_THAI_KQ !== trang_thai_tra.CHO_KET_QUA) {
        check = true;
      } else {
        check = false;
      }
      return check;
    };

    const onAfterSubmit = () => {
      if (contentRef.current) {
        contentRef.current.setDescription("");
        contentRef.current.setKetLuan();
        contentRef.current.setKyThuat();
        setPhanLoai()
        setSelectedMau();
        // setSelectedMay();
      }
      setArrImgPrev([]);
      setSeletedImage([]);
    };

    const convertHtmlTag = (string) => {
      if (typeof string === "string") {
        if (string.includes(`'`)) {
          return string.replace(`'`, `"`);
        }
        return string;
      }
    };

    // xử lý lưu trả dịch vụ
    const handleSubmit = async (values) => {
      const promises = seletedImage?.filter(obj => !obj?.ID)?.map(async (value) => {
        try {
          let ID = `${moment().valueOf()}${value.key}`
          let result = await uploadImageBase64(value?.thumbnail, ID, userProfile.partner_code);
          let obj = {
            PDT_ID: currService?.PDT_ID,
            DICH_VU_ID: currService?.DICH_VU_ID,
            LUOT_DIEU_TRI_ID: currService?.LUOT_DIEU_TRI_ID,
            ID_THUMBNAIL: result,
            KEY_IMG: value.key,
            IS_ADD: !!value?.isAdded,
            TRANG_THAI: true
          }
          return obj;
        } catch (error) {
          console.error('Error calling API Z:', error);
          // Bạn có thể đẩy một giá trị đặc biệt vào B để đánh dấu lỗi nếu cần
          return null; // Hoặc một giá trị mặc định khác
        }
      });

      const arrayObjListImag = await Promise.all(promises);

      let arrayx = [].concat(arrayObjListImag).concat(seletedImage?.filter(obj => obj?.ID) || [])?.map(objMap => {
        let objcv = {
          PDT_ID: currService?.PDT_ID,
          DICH_VU_ID: currService?.DICH_VU_ID,
          LUOT_DIEU_TRI_ID: currService?.LUOT_DIEU_TRI_ID,
          ID_THUMBNAIL: objMap?.ID_THUMBNAIL,
          KEY_IMG: objMap?.KEY_IMG,
          IS_ADD: !!objMap?.IS_ADD || !!objMap?.isAdded,
          TRANG_THAI: true,
          ID: objMap?.ID || null,
        }
        return objcv;
      })

      let { NGUOI_THUC_HIEN_ID, NGUOI_TRA_KQ_ID } = values

      if (!selectedPhong) {
        notification.error({
          message: i18n.t(languageKeys.noti_vui_long_chon_phong_thuc_hien),
          placement: "bottomLeft",
        });
        return;
      }

      if (!values.MAY_THUC_HIEN) {
        notification.error({
          message: i18n.t(languageKeys.noti_vui_long_chon_may_thuc_hien),
          placement: "bottomLeft",
        });
        return;
      }
      let arr_anh_ID = [];

      let MO_TA = contentRef.current.description && convertHtmlTag(contentRef.current.description);
      let ket_luan = contentRef.current.ketLuan;
      let KY_THUAT = contentRef.current.kyThuat;
      if (!removeTags(MO_TA).trim()) {
        notification.error({
          message: "Vui lòng nhập mô tả",
          placement: "bottomLeft",
        });
        return;
      } else if (!ket_luan) {
        notification.error({
          message: "Vui lòng nhập kết luận",
          placement: "bottomLeft",
        });
        return;
      }
      try {
        setLoading(true);
        let phieu_tra_id = currService?.PHIEU_TRA_ID;
        const arrayFile = await handleSaveFile()
        if (!arrayFile || arrayFile?.length == 0) return ""
        let req = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          NGUOI_THUC_HIEN_ID: NGUOI_THUC_HIEN_ID || userProfile.NHANSU_ID,
          NGUOI_TRA_KQ_ID: NGUOI_TRA_KQ_ID || userProfile.NHANSU_ID,
          PHIEU_TRA_ID: phieu_tra_id,
          MA_PHIEU: phieu.MA_PHIEU,
          NGUOI_TRA_KET_QUA: userProfile.NHANSU_ID,
          MO_TA,
          MAU_MO_TA_ID: selectedMau ? selectedMau.ID : null,
          MAY_THUC_HIEN_ID: selectedMay.ID,
          KET_LUAN: ket_luan,
          KY_THUAT: KY_THUAT || null,
          NOI_CHI_DINH: values.NOI_CHI_DINH,
          PHONG_TRA_KET_QUA: selectedPhieu?.PHONG_ID || currService?.PHONG_ID,
          //list_ANH_ID: [...arr_anh_ID, ...arr_upload],
          list_ANH_ID: [],
          // huy bảo thêm 27/04/2023
          CT_PHIEU_ID: currService?.CT_PHIEU_ID,
          PDT_ID: currService?.PDT_ID,
          PHIEU_ID: phieu.PHIEU_ID,
          NHANSU_ID: userProfile.NHANSU_ID,
          PHONG_ID: patientInfo.PHONG_ID,
          LICH_KHAM_ID: phieu?.LICH_KHAM_ID,
          LUOT_DIEU_TRI_ID: phieu?.LUOT_DIEU_TRI_ID,
          FILE: _.isArray(arrayFile) ? arrayFile : [],
          PHAN_LOAI_BENH_TAT: contentRef.current.phanLoai,
          DICH_VU_ID: currService?.DICH_VU_ID,
          FILE_ANH_ARR: arrayx,
          GHI_CHU: values.GHI_CHU,
          HIEN_THI_CHU_KY: +showElectronicSign
        };

        const size = getSizePayload(req);
        console.log("SIZE PAYLOAD mb", size);
        if (size > window.FILE_MAX_SIZE_MB) {
          notification.warning({
            message: i18n.t(languageKeys.warning_over_size),
            placement: "bottomLeft",
          });
          setLoading(false);
          return;
        }
        let res = await common_post(apis.ris_luu_ket_qua_phieu, req, false);
        if (res && res.status === "OK") {
          delete req.BENH_VIEN_ID;
          delete req.partner_code;
          delete req.PHIEU_TRA_ID;
          delete req.NGUOI_TRA_KET_QUA;

          let newDsDv;
          let { data } = res;

          let currentDv = {
            ...currService,
            TRANG_THAI_KQ: trang_thai_tra.DA_TRA_KET_QUA,
            DA_TRA: 1,
            ...req,
            NGUOI_TRA_KQ: `${userProfile.HO} ${userProfile.TEN}`,
            GIO_TRA_KET_QUA: data.GIO_TRA_KET_QUA,
            NGAY_TRA_KET_QUA: data.NGAY_TRA_KET_QUA,
          };

          setCurrentListDv((prevDsDv) => {
            newDsDv = [...prevDsDv];

            let index = newDsDv.findIndex((item, index) => {
              return item.PHIEU_TRA_ID === phieu_tra_id;
            });

            newDsDv[index] = currentDv;

            return newDsDv;
          });
          doAfterSubmit(currentDv);
          onAfterSubmit();

          if (!newDsDv.length) {
            handleClose();
          } else {
            let nextItem = newDsDv.find((item) => item.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA);
            if (!!nextItem) {
              setCurrService(nextItem);
              form.setFieldValue('GHI_CHU', nextItem?.GHI_CHU)
            } else {
              let timeOut;

              try {
                timeOut = getState() ? 3000 : 0;
              } catch (error) {
                timeOut = 3000;
              }
              handleClose(timeOut);
            }
          }
          if (getState()) {
            handlePrint([currService?.PHIEU_TRA_ID], inPacs);
          }
          notification.success({
            message: i18n.t(languageKeys.noti_Nhap_du_lieu_thanh_cong),
            placement: "bottomLeft",
          });
          dispatch(setIsChange(false));
        } else {
          if (res.error_code && res.error_code === "002") {
            let error;
            switch (res.field) {
              case "MAY_THUC_HIEN_ID":
                error = i18n.t(languageKeys.may_thuc_hien);
                break;
              case fieldKetQua.THOI_GIAN_CHI_DINH:
                error = i18n.t(languageKeys.thoi_gian_chi_dinh);
                break;
              case fieldKetQua.NGAY_TRA_KQ:
                error = i18n.t(languageKeys.ngay_tra_kq);
                break;
              case "MO_TA":
                error = i18n.t(languageKeys.mo_ta);
                break;
              default:
                error = "";
                break;
            }

            notification.error({
              message: (
                <>
                  {i18n.t(languageKeys.loi_nhap_form) + " "} <span style={{ textTransform: "lowercase" }}>{error}</span>
                </>
              ),
              placement: "bottomLeft",
            });
          }
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        HLog(error);
      }
    };

    // trigger action in phiếu
    const onPressPrint = (pacs = false) => {
      handlePrint([currService?.PHIEU_TRA_ID], pacs);
    };

    // in cả phiếu
    async function handlePrint(array_id, pacs = false) {
      let req = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        PHIEU_ID: array_id,
      };
      let response = await apiPhieuTra(req, pacs);
      if (response) {
        let array = response.result;
        let data_print = array.map((item) => {
          let data = {
            ...item,
            maumota: item.MO_TA,
            ket_luan: item.KET_LUAN,
            arr_anh_ID: item.ANH_MO_TA,
            MA_PHIEU: selectedPhieu?.MA_PHIEU
          };
          return data;
        });

        const template = !pacs ? dataLocal?.template_pdf?.PHIEU_TRA_KQ_RIS || "PhieuTraKq" : "PhieuTraKqPACS";

        if (!template) {
          notification.error({
            message: "Không có mẫu in!",
            placement: "bottomLeft",
          });
          return;
        }
        pdfRef.current?.openModalWithData(data_print, template);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSetCurrService = useCallback((dichvu) => {
      dispatch(setIsChange(false));
      setCurrService(dichvu);
      form.setFieldValue('GHI_CHU', dichvu?.GHI_CHU)
    }, []);

    const handleOpenCam = () => {
      if (webcamRef.current) webcamRef.current.open();
    };

    const handleHuyTra = async () => {
      try {
        let body = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          CHITIET_PHIEU_TRA_ID: [currService?.PHIEU_TRA_ID],
          PHIEU_ID: phieu.PHIEU_ID,
          NHANSU_ID: userProfile.NHANSU_ID,
          PHONG_DIEU_TRI_ID: currService?.PDT_ID,
          DICH_VU_ID: currService?.DICH_VU_ID,
        };
        let res = await common_post(apis.ris_huy_dich_vu_phieu_tra_kq, body, false);
        if (res && res.status === "OK") {
          doAfterDestroy(currService?.DICH_VU_ID);

          let currentDv = { ...currService, TRANG_THAI_KQ: trang_thai_tra.CHO_KET_QUA };

          setCurrentListDv((prevDsDv) => {
            let newDsDv = [...prevDsDv];

            let index = newDsDv.findIndex((item, index) => {
              return item.DICH_VU_ID === currService?.DICH_VU_ID;
            });

            newDsDv[index] = currentDv;

            return newDsDv;
          });
          setCurrService((prevService) => ({
            ...prevService,
            TRANG_THAI_KQ: trang_thai_tra.CHO_KET_QUA,
          }));
          form.resetFields([fieldKetQua.NGAY_TRA_KQ, fieldKetQua.NGUOI_TRA_KQ]);
          if (!!selectedRecord?.IMAGE_URL) setResultUrl(Array?.isArray(selectedRecord?.IMAGE_URL) ? selectedRecord?.IMAGE_URL[0] : (JSON.parse(selectedRecord?.IMAGE_URL)[0] || ''));
          if (!!selectedRecord?.LINK_VIEW) setListImageUrl(selectedPhong.LINK_VIEW);
          if (selectedRecord?.IS_CONNECT_VIN === false) setIsConnectVin(false)
          if (!!selectedRecord?.IMAGE_URL_ARR) {
            importListImageFromUrl(selectedRecord?.IMAGE_URL_ARR)
          }
          if (!!selectedRecord?.ANH_RIS && selectedRecord?.ANH_RIS?.length > 0) {
            getListImage2(selectedRecord?.ANH_RIS)
          } else {
            setSeletedImage([])
          }
          notification.success({
            message: i18n.t(languageKeys.thao_tac_thanh_cong),
            placement: "bottomLeft",
          });
        } else {
          notification.error({
            message: i18n.t(languageKeys.noti_tra_kq_that_bai),
            placement: "bottomLeft",
          });
        }
      } catch (error) {}
    };

    const getDsNhanVien = async () => {
      try {
        const payload = {
          search_string: "",
          NHOM_NHAN_SU: ["QUAN_TRI", "BAC_SI", "KY_THUAT_VIEN_CDHA"],
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
        };
        const res = await common_post(apis.lay_danh_sach_nhan_vien_co_hieu_luc, payload);
        if (res && res.status == "OK") {
          let { result } = res;
          dispatch(KhamBenhActions.setDsBacSiRIS(result));
        } else {
          dispatch(KhamBenhActions.setDsBacSiRIS([]));
        }
      } catch (error) {
        dispatch(KhamBenhActions.setDsBacSiRIS([]));
      }
    };


    useEffect(() => {
      if (dsBacSiRis?.length == 0) {
        getDsNhanVien();
      }
    }, []);

    // ============================================Effect Cập nhật===================================================================

    // Dịch vụ cụ thể đang hiển thị, nếu search sẽ chuyển sang danh sách dịch vụ được truyền từ props
    useEffect(() => {
      if (visible) {
        setCurrentListDv(dsDichvu.map((dichvu) => ({ ...dichvu, key: rid() })));
        if (phieu && !isEmptyObject(phieu)) {
          let fieldData = Object.keys(fieldKetQua).map((item) => {
            if (item.includes(fieldKetQua.THOI_GIAN_Y_LENH)) {
              return {
                name: item,
                value: moment(Number(phieu[fieldKetQua.THOI_GIAN_Y_LENH])).format("HH:mm - DD/MM/YYYY"),
              };
            }

            if (item.includes('THOI_GIAN_CHI_DINH') && !!phieu['NGAY_CHI_DINH']) {
              return {
                name: item,
                value: `${phieu?.GIO_CHI_DINH || ''} ${convertDateToValue(phieu['NGAY_CHI_DINH'])}`,
              };
            }

            if (item.includes("NGAY") && !!phieu[item]) {
              return {
                name: item,
                value: convertDateToValue(phieu[item]),
              };
            }

            // if (item === fieldKetQua.NGUOI_TRA_KQ_ID || item === fieldKetQua.NGUOI_THUC_HIEN_ID) {
            //   return {
            //     name: item,
            //     value: phieu[item] ? phieu[item] : userProfile?.NHANSU_ID,
            //     errors: [],
            //   };
            // }

            return {
              name: item,
              value: phieu[item],
            };
          });
          form.setFields(fieldData);
          layDsMay();
        }
      } else {
        setCurrentListDv([]);
        setCurrService();
        setArrImgPrev([]);
        setSeletedImage([]);
        setSelectedRecord();
        setListImageUrl('');
        setResultUrl('');
        setDsMay([]);
        setSelectedMay();
        setReturnHistory(false);
        dispatch(resetRepo());
        setSelectedMau();
        form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    // set Value May
    useEffect(() => {
      if (selectedMay) {
        form.setFields([
          {
            name: "MAY_THUC_HIEN",
            value: selectedMay?.ID,
          },
          {
            name: fieldKetQua.MAY_THUC_HIEN,
            value: selectedMay?.TEN_MAY,
          },
        ]);
      }
      return () => {
        form.resetFields([fieldKetQua.MAY_THUC_HIEN]);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMay]);

    const triggerFillDescription = (selectMau) => {
      setSelectedMau(JSON.parse(selectMau));
      if (!!selectMau) {
        if (contentRef.current) {
          contentRef.current.setDescription(JSON.parse(selectMau).MO_TA);
          contentRef.current.setKetLuan(JSON.parse(selectMau).KET_LUAN);
        }
      } else {
        if (contentRef.current) {
          contentRef.current.setDescription("");
          contentRef.current.setKetLuan();
        }
      }
    };
    // cập nhật action khi chuyển dịch vụ
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const getListImage2 = async (arrayProps) => {
      try {
        const cpArrProps = [...arrayProps].filter(p => !!p.ID_THUMBNAIL);

        const arrayImageBase64 = cpArrProps.map(async (item) => {
          const imageUrl = getPrivateImageLink(item.ID_THUMBNAIL);
          return getBase64FromUrlWithCompression(imageUrl);
        })
        const base64Images = await Promise.all(arrayImageBase64);
        setSeletedImage(base64Images.map((obj, index) => ({ ...arrayProps[index], isAdded: arrayProps[index]?.IS_ADD, key: arrayProps[index]?.KEY_IMG, thumbnail: obj.replace("data:application/octet-stream", "data:image/jpeg") })))

      } catch (error) {
        console.log(error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      setFileList([])
      setDeleteFileList([])
      if (!!currService) {
        // điều kiện cho phép trả kết quả
        if (currService?.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA) {
          setArrImgPrev([]);
          // layChiTietDichvuDaTra(currService?.PHIEU_TRA_ID);
          setSeletedImage([]);
          // setArrImgPrev([]);

          setSelectedRecord();

          let body = {
            partner_code: userProfile.partner_code,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            PHIEU_TRA_ID: currService?.PHIEU_TRA_ID,
          };
          let res = await common_post(apis.ris_lay_chi_tiet_phieu_tra, body, false);

          if (res && res.status === "OK") {
            let { result } = res;
            if (!!result[0]?.ANH_RIS && result[0]?.ANH_RIS?.length > 0) {
              getListImage2(result[0]?.ANH_RIS)
            }
          }

          if (contentRef.current) {
            contentRef.current.setDescription("");
            contentRef.current.setKetLuan();
            contentRef.current.setKyThuat();
          }
          if (!!currService?.DA_TRA && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA) {
            // nếu dịch vụ đã TỪNG trả kết quả thì gọi api
            // setReturnHistory(true);
            layChiTietDichvuDaTra(currService?.PHIEU_TRA_ID);
          } else {
            // setReturnHistory(false);
            dsMay?.length > 0 && setSelectedMay(dsMay[0]);
            if (currService?.MO_TA || currService?.KET_LUAN) {
              // neeus dịch vụ có mô tả kết luận rồi thì điền vào
              setTimeout(() => {
                contentRef.current.setDescription(currService?.MO_TA);
                contentRef.current.setKetLuan(currService?.KET_LUAN);
                contentRef.current.setKyThuat(currService?.KY_THUAT);
              }, 100)
              const currentMau = dsMau.find((item) => item.ID === currService?.MAU_MO_TA_ID);
              if (currentMau) {
                // tìm xem nếu có mẫu id thì set mãu đã chọn sau có reset thì còn điền lại
                setSelectedMau(currentMau);
              }
              await layDsMauMoTa("", currService?.DICH_VU_ID, currService?.MAU_MO_TA_ID, false); // lấy danh sách nhưng không tự fill vào form
            } else {
              // trường hợp mà chưa có kết luận mô tả thì lấy mẫu và tự động điền mẫu vào form
              await layDsMauMoTa("", currService?.DICH_VU_ID, null, true);
            }
          }
        } else if (currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA) {
          // nếu trạng thái dịch vụ là đã trả kết quả
          layChiTietDichvuDaTra(currService?.PHIEU_TRA_ID);
        }
      } else {
        setDsMau([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currService]);

    // xử lý phím tắt
    useEventListener("keydown", keydownEvent, window.document, visible && !!currService);

    // đặt log================================================================

    const removeAll = () => {
      setSeletedImage([]);
      removeMultipleImageInDescription(seletedImage);
      confirmRemoveAllRef.current.close();
    };

    const onChangeContentFormNhap = (values) => {
      setSeletedImage(prev => {
        return prev.map(item => {
          if (values.includes(item.thumbnail) && values.includes(`id="${item.key}"`)) {
            return { ...item, isAdded: true };
          } else {
            return { ...item, isAdded: false };
          }
        })
      });
    };

    const handleFinishImport = (arrayImages) => {
      if (arrayImages.length > 0) {
        let total_current_img = seletedImage.length;
        let total_imported = arrayImages.length;

        if (total_current_img >= 8) {
          importRef?.current.close();
          notification.error({
            message: i18n.t(languageKeys.so_luong_anh_toi_da),
            placement: 'bottomLeft'
          })
          return;
        }
        let total_image_free = 8 - total_current_img;
        let add_array;
        if (total_imported <= total_image_free) {
          add_array = [...arrayImages].map((item) => ({
            thumbnail: item.image,
            key: rid(),
          }));
        } else {
          add_array = [...arrayImages].splice(0, total_image_free).map((item) => ({
            thumbnail: item.image,
            key: rid(),
          }));
        }

        importRef.current.close();
        setSeletedImage((prev) => prev.concat(add_array));
        fillImage(add_array)
      } else {
        importRef.current.close();
      }
    };


    const fillImage = (data) => {
      let string = contentRef.current?.description || "";
      let imageArray = [...data];
      let imageArrayIndex = 0;

      string = string.replace(/&lt;image&gt;/g, function (match) {
        if (imageArrayIndex < imageArray.length) {
          var imageTag = '<img src="' + imageArray[imageArrayIndex].thumbnail + '" id="' + imageArray[imageArrayIndex].key + '">';
          imageArrayIndex++;
          return imageTag;
        }
        else {
          return match;
        }
      });
      contentRef.current.setDescription(string);
    };

    const handleClickEditImage = (image) => {
      editImageRef.current.open(image);
    };

    const handleSubmitEditImage = (image) => {
      editImageRef.current.close();
      let string = contentRef.current?.description || "";
      let result = string.match(/<img .*?src=['"](.*?)['"].*?>/g);
      setArrImgPrev(arrImgPrev.map(item => item.key === image.key ? {
        ...item,
        thumbnail: image.thumbnail
      } : item))
      if (result && result.length > 0) {
        const itemRemove = result.find((el) => el.includes(`id="${image?.key}"`));
        if (itemRemove) {
          const regex = /src="data:image\/jpeg;base64,([^"]+)"/;
          const matches = itemRemove.match(regex);
          if (matches && matches.length === 2) {
            string = string.replace(matches[0], `src="${image.thumbnail}"`);
            contentRef.current.setDescription(string);
          }
        }
      }
      setSeletedImage((prev) => {
        return prev.map((item) => {
          if (item.key === image.key) {
            return { ...item, thumbnail: image.thumbnail };
          }
          return item;
        });
      });
    };

    const handleClickImg = (image) => {
      // let string = contentRef.current?.description || '';
      // let result =  string.replace("&nbsp;</td>", `<img class="ant-image-img" style='max-width: 100%; max-height: 100%; object-fit: cover' src="${image}" /></td>`)
      // contentRef.current.setDescription(result);
    };

    const handleResetForm = () => {
      if (selectedMau) {
        contentRef.current.setDescription(selectedMau.MO_TA);
        contentRef.current.setKetLuan(selectedMau.KET_LUAN);
      } else {
        contentRef.current.setDescription("");
        contentRef.current.setKetLuan("");
        // setPhanLoai()
        setPhanLoai()
      }
      setSeletedImage([]);
    };

    // Xoá 1
    // const removeImageInDescription = (image) => {
    //   let string = contentRef.current?.description || "";
    //   let result = string.match(/<img .*?src=['"](.*?)['"].*?>/g);
    //   if (result && result.length > 0) {
    //     const itemRemove = result.find((el) => el.includes(`id="${image?.key}"`));
    //     console.log(itemRemove, 'itemRemove')
    //     string = string.replace(itemRemove, "");
    //     contentRef.current.setDescription(string);
    //   }
    // }

    const removeMultipleImageInDescription = (arr_img) => {
      let string = contentRef.current?.description || "";
      let result = string.match(/<img .*?src=['"](.*?)['"].*?>/g);
      if (result && result.length > 0) {
        const itemsRemove = result?.filter((el) => !!arr_img.find(imgItem => el.includes(`id="${imgItem?.key}"`)));
        itemsRemove?.forEach(item => {
          string = string.replace(item, '')
        })
      }
      contentRef.current.setDescription(string);
    }


    const updateEditedImagesInDescription = (arr_delete, arr_edited_img) => {
      let string = contentRef.current?.description || "";
      let result = string.match(/<img .*?src=['"](.*?)['"].*?>/g);

      if (result && result.length > 0) {
        const itemsRemove = result?.filter((el) => !!arr_delete.find(imgItem => el.includes(`id="${imgItem?.key}"`)));
        itemsRemove?.forEach(item => string = string.replace(item, ''))

        const itemEdited = result.map((el) => {
          const edited = arr_edited_img.find(imgItem => el.includes(`id="${imgItem?.key}"`))
          return !!edited ? {
            string: el,
            thumbnail: edited?.thumbnail
          } : null
        });
        const regexJPEG = /src="data:image\/jpeg;base64,([^"]+)"/;
        itemEdited?.forEach(item => {
          if (!!item) {
            const matches = item?.string.match(regexJPEG);

            if (matches && matches.length === 2) {
              string = string.replace(matches[0], `src="${item?.thumbnail}"`);
            }
          }
        })
      }
      contentRef.current.setDescription(string);
    }

    const handleHuyThucHien = () => {
      handleChuyenTrangThaiDichVu(currService, trang_thai_th.CHO_THUC_HIEN).then(res => {
        if (res && res.status === 'OK') {
          handleClose();
        } else {
          notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: "bottomLeft" })
        }
      })
    }
    const onClickButtonFile = () => {
      setVisible2(true)
    }
    const handleCloseDrawFile = () => {
      setVisible2(false)
    }

    const handleSaveFile = async (letArrayNew) => {

      if (letArrayNew) {
        if (letArrayNew?.length == 0) return true
        let arrayFile = []
        const formData = new FormData();
        letArrayNew.forEach((fileObj) => {
          formData.append("files", fileObj.origin_file);
        });

        try {
          const response = await common_post(apis.luuFileMoi, formData);
          if (response && response.status === "OK") {
            arrayFile = response.result

          }
        } catch (error) {
          arrayFile = false
        }
        return arrayFile
      } else {
        if (fileList?.length == 0) return true
        let arrayFile = []
        let letArrayNew = []

        fileList.forEach((fileObj) => {
          if (fileObj?.isOld) {
            arrayFile.push(fileObj)
          } else {
            letArrayNew.push(fileObj)
          }
        });
        const formData = new FormData();
        letArrayNew.forEach((fileObj) => {
          formData.append("files", fileObj.origin_file);
        });

        try {
          const response = await common_post(apis.luuFileMoi, formData);
          if (response && response.status === "OK") {
            arrayFile = [...arrayFile, ...response.result]
          } else {
            arrayFile = false
            notification.error({
              message: i18n.t(languageKeys.note_up_file_that_bai),
              placement: "bottomLeft",
            })
          }
        } catch (error) {
          notification.error({
            message: i18n.t(languageKeys.note_up_file_that_bai),
            placement: "bottomLeft",
          });
          arrayFile = false
        }
        return arrayFile.map((obj) => ({ ID: obj.ID, NGAY: obj.NGAY, ORIGINAL_NAME: obj.ORIGINAL_NAME }))
      }

    }

    const handleOkDrawFile = async () => {
      setVisible2(false);
    }

    const handleSetUpload = (e) => {
      let { files } = e.target;
      if (files.length === 0) {
        return;
      }

      const newFiles = Array.from(files)
        .map((file) => {
          if (!file.name.toLowerCase().includes(".pdf")) {
            notification.error(i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang));
            return null;
          }
          const TEN_FILE = file.name.split(".pdf")[0];
          return { origin_file: file, item: file, FILE_ID: nanoid(5), TEN_FILE };
        })
        .filter((file) => file !== null)
      if (newFiles.length > 0) {
        setFileList((prev) => {
          return [...prev, ...newFiles]
        });
      }

      e.target.value = ''

    };

    const handleClickFile = async (file) => {
      if (!file?.isOld) {
        const da = await file
        let link = URL.createObjectURL(da.origin_file)
        window.open(link, "_blank");
      } else {
        try {

          const response = await common_get(`${apis.layFileMoi}?ID=${file.FILE_ID}`, {
            ID: file.FILE_ID
          });

          const createObjectURLFromBase64 = (base64, contentType) => {
            console.log("base64", base64);
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });
            let url = window.URL.createObjectURL(blob);

            window.open(url, "_blank");
          };

          createObjectURLFromBase64(response, "application/pdf");

        } catch (error) {
          console.log(error);
        }
      }
    };

    const handleDelFile = (itemDel) => {
      if (!!itemDel) {
        let deleteItem = itemDel.FILE_ID ? fileList.find((item) => item.FILE_ID === itemDel.FILE_ID) : null;
        setDeleteFileList((prev) => [...prev, deleteItem]);
        setFileList((prev) => prev.filter((item) => item.FILE_ID !== itemDel.FILE_ID));
      }
    };

    const renderOptions = () => {
      switch (currService?.MA_LOAI_DV) {
        case "LDV-SA":
          return PhanLoaiSieuAmRIS;
        case "CDHA_XQUANG":
          return PhanLoaiXQuangRIS;
        default:
          return [];
      }
    }

    return (
      <Drawer
        visible={visible}
        width={widthDrawerKeys.large}
        closable
        onClose={handleClose}
        className={style["form-container"]}
        closeIcon={false}
        maskClosable={false}
        title={<Topbar patientInfo={patientInfo} handleClose={handleClose} vattuRef={vattuRef} />}
        footer={
          !currService ? (
            <Row gutter={10} justify="end">
              <Col>
                <Button type="primary" ghost onClick={() => handleClose()}>
                  {i18n.t(languageKeys.common_Thoat)} (ESC)
                </Button>
              </Col>
            </Row>
          ) : currService?.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA ? (
            <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <h2 style={{ fontSize: 15 }}>{i18n.t(languageKeys.phan_loai_bt)}</h2>
                  <Select
                    value={phanLoai}
                    dataSource={renderOptions()}
                    valueKey='value'
                    titleKey='label'
                    onChange={setPhanLoai}
                    className={style.selectEditor}
                    disabled={currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA}
                  />
                  <div>
                    <Checkbox className="navy-txt" checked={showElectronicSign} onChange={e => setShowElectronicSign(e.target.checked)}>{i18n.t(languageKeys.ky_dien_tu)}</Checkbox>
                  </div>
                </div>
                <Row gutter={10} justify="end">
                  <Col>
                    <Button type="primary" danger ghost onClick={() => handleResetForm()} loading={loadingDsMay} disabled={isConnectVin}>
                      {i18n.t(languageKeys.lam_moi)} (Ctrl + R)
                    </Button>
                  </Col>

                  <Col>
                    <Button type="primary" ghost onClick={() => handleClose()}>
                      {i18n.t(languageKeys.common_Thoat)} (ESC)
                    </Button>
                  </Col>
                  <Col>
                    <TooltipButton title={disabledHuyTH ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) : ""}>
                      <Button type="primary" ghost onClick={() => handleHuyThucHien()} disabled={disabledHuyTH}>
                        {i18n.t(languageKeys.huy_thuc_hien_full)} (Ctrl + D)
                      </Button>
                    </TooltipButton>
                  </Col>
                  <Col>
                    <TooltipButton title={disableTraKQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button
                        type="primary"
                        // disabled={isAllowQuyenRis.isAllowTraPhieu ? disableSubmit() : true}
                        onClick={() => {
                          setState(false);
                          //handleChuyenTrangThaiDichVu(currService, trang_thai_th.DA_THUC_HIEN)
                          setInPacs(false);
                          form.submit();
                        }}
                        loading={loading}
                        disabled={disableTraKQ}
                      >
                        {i18n.t(languageKeys.tra_ket_qua)} (Ctrl + K)
                      </Button>
                    </TooltipButton>
                  </Col>
                  <Col>
                    <Popover placement="top" content={(
                      <>
                        <TooltipButton title={disableTraKQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                          <Button
                            type="primary"
                            // disabled={isAllowQuyenRis.isAllowTraPhieu ? disableSubmit() : true}
                            style={{ width: 220, marginBottom: 10 }}
                            onClick={() => {
                              setState(true);
                              //handleChuyenTrangThaiDichVu(currService, trang_thai_th.DA_THUC_HIEN)
                              setInPacs(false);
                              form.submit();
                            }}
                            loading={loading}
                            disabled={disableTraKQ}
                          >
                            {i18n.t(languageKeys.tra_ket_qua_va_in)} (CTRL + L)
                          </Button>
                        </TooltipButton>
                        <br />
                        <TooltipButton title={disableTraKQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                          <Button
                            type="primary"
                            // disabled={isAllowQuyenRis.isAllowTraPhieu ? disableSubmit() : true}
                            style={{ width: 220 }}
                            onClick={() => {
                              setState(true);
                              //handleChuyenTrangThaiDichVu(currService, trang_thai_th.DA_THUC_HIEN)
                              setInPacs(true);
                              form.submit();
                            }}
                            loading={loading}
                            disabled={disableTraKQ}
                          >
                            Trả KQ và In PACS (CTRL + P)
                          </Button>
                        </TooltipButton>
                      </>
                    )}>
                      <Button
                        type="primary"
                        // disabled={isAllowQuyenRis.isAllowTraPhieu ? disableSubmit() : true}
                        onClick={() => {
                          setState(true);
                          //handleChuyenTrangThaiDichVu(currService, trang_thai_th.DA_THUC_HIEN)
                          setInPacs(false);
                          form.submit();
                        }}
                        loading={loading}
                        disabled={disableTraKQ}
                      >
                        {i18n.t(languageKeys.tra_ket_qua_va_in)} (CTRL + L)
                      </Button>
                    </Popover>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <h2 style={{ fontSize: 15 }}>{i18n.t(languageKeys.phan_loai_bt)}</h2>
                  <Select
                    value={phanLoai}
                    dataSource={renderOptions()}
                    valueKey='value'
                    titleKey='label'
                    onChange={setPhanLoai}
                    className={style.selectEditor}
                    disabled={currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA}
                  />
                </div>
                <Row gutter={10} justify="end">
                  <Col>
                    <Button type="primary" ghost onClick={() => handleClose()}>
                      {i18n.t(languageKeys.common_Thoat)} (ESC)
                    </Button>
                  </Col>
                  <Col>
                    <TooltipButton title={disableHuyKQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button disabled={disableHuyKQ} type="danger" ghost onClick={handleHuyTra} loading={loading}>
                        {i18n.t(languageKeys.huy_tra)} (Ctrl + D)
                      </Button>
                    </TooltipButton>
                  </Col>
                  <Col>
                    <TooltipButton title={disableIn ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button type="primary" onClick={() => onPressPrint()} loading={loading} disabled={disableIn}>
                        {i18n.t(languageKeys.field_In_phieu)} (CTRL + I)
                      </Button>
                    </TooltipButton>
                  </Col>
                  <Col>
                    <TooltipButton title={disableIn ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button type="primary" onClick={() => onPressPrint(true)} loading={loading} disabled={disableIn}>
                        In KQ PACS (CTRL + Y)
                      </Button>
                    </TooltipButton>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
      >
        <Form form={form} layout="horizontal" style={{ height: "100%" }} className={style["container"]} onFinish={handleSubmit}>
          <div className={style["form__header"]}>
            <Row gutter={10}>
              <Col span={9}>
                <Item label={i18n.t(languageKeys.chon_mau_mo_ta)} name="CHON_MAU">
                  <Select
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    titleKey={"TEN_MAU"}
                    readOnly={disableTraKQ || (currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA)}
                    // valueKey={"MAU_ID"}
                    dataSource={dsMau}
                    loading={loadingDsMay}
                    showSearch
                    onSearch={(value) => {
                      if (currService) {
                        searchDsMau(value, currService?.NHOM_DV_ID, currService?.LOAI_DV_ID);
                      }
                    }}
                    allowClear={currService && currService?.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA}
                    onChange={(value) => {
                      dispatch(setIsChange(true));
                      triggerFillDescription(value);
                    }}
                    value={selectedMau}
                    className={style.selectEditor}
                  />
                </Item>
              </Col>
              <Col span={9}>
                <Item
                  label={i18n.t(languageKeys.chon_may_thuc_hien)}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  name={fieldKetQua.MAY_THUC_HIEN}
                >
                  <Select
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    allowClear={currService && currService?.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA}
                    dataSource={dsMay}
                    readOnly={disableTraKQ || (currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA)}
                    titleKey={"TEN_MAY"}
                    loading={loadingDsMay}
                    onChange={(item) => {
                      dispatch(setIsChange(true));
                      setSelectedMay(item ? JSON.parse(item) : item);
                    }}
                  />
                </Item>
              </Col>
              {currService && currService?.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA ? (
                <Col span={6} style={{ maxHeight: 32 }}>
                  <div style={{ display: "flex", gap: 8 }}>

                    <label htmlFor="male">
                      <TooltipButton widthTip="200px" title={disableTraKQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                        <div
                          className={classNames(style["btn__custom--upload"], disableTraKQ && style["btn__upload--disabled"])}
                          onClick={() => importRef.current.open()}
                        >
                          <div style={{ margin: "auto", textWrap: "nowrap" }}>
                            <PaperClipOutlined style={{ marginRight: 6 }} />

                            {i18n.t(languageKeys.btn_chen_anh)}
                          </div>
                        </div>
                      </TooltipButton>
                    </label>
                    <TooltipButton title={disableTraKQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button
                        type="primary"
                        className={style["btn__top--action"]}
                        onClick={() => {
                          window.navigator.mediaDevices
                            .getUserMedia({ video: true, audio: false })
                            .then((stream) => {
                              handleOpenCam();
                            })
                            .catch((err) => {
                              notification.error({ message: i18n.t(languageKeys.noti_vui_long_ket_noi_camera), placement: "bottomLeft" });
                            });
                        }}
                        disabled={disableTraKQ}
                        icon={<CameraOutlined />}
                      >
                        {i18n.t(languageKeys.btn_chup_anh)}
                      </Button>
                    </TooltipButton>
                  </div>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
          <Layout className={style["container"]}>
            <SiderFormNhap
              listDichVu={currentListDv}
              phieu={phieu}
              setCurrService={onSetCurrService}
              currService={currService}
              onFilter={handleFilter}
            />
            <Content style={{ background: "#fff", height: "100%" }}>
              <HeaderElement title={i18n.t(languageKeys.thong_tin)} onOpen={() => setOpenTTPhieu(!openTTPhieu)} open={openTTPhieu} bgColor={"#FFF"} />
              <div className={style["form__header--thongtin"]} style={openTTPhieu ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}>
                <Row gutter={[10, 10]} className={style["form-wrap"]}>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.ma_phieu)}</span>
                    <Item label="" name={fieldKetQua.MA_PHIEU}>
                      <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                  </Col>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.thoi_gian_chi_dinh)}</span>
                    <Item label="" name={fieldKetQua.THOI_GIAN_CHI_DINH}>
                      <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                  </Col>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.noi_chi_dinh)}</span>
                    <Item label="" name={fieldKetQua.NOI_CHI_DINH}>
                      <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                  </Col>
                  <Col flex={"40%"}>
                    <span>{i18n.t(languageKeys.chan_doan)}</span>
                    <Item label="" name={fieldKetQua.CHAN_DOAN}>
                      <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                  </Col>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.noi_thuc_hien)}</span>
                    <Item label="" name={fieldKetQua.NOI_THUC_HIEN}>
                      <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                  </Col>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.nguoi_thuc_hien)}</span>
                    <Item label="" name={'NGUOI_THUC_HIEN_ID'}>
                      <SelectAntd
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA}
                        options={dsBacSiRis?.map(obj => ({ label: obj.HO + " " + obj.TEN, value: obj.NHANSU_ID, key: obj.NHANSU_ID }))}
                        placeholder={i18n.t(languageKeys.common_Chon)}
                      />
                    </Item>
                  </Col>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.field_may_thuc_hien)}</span>
                    <Item label="">
                      <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} value={selectedMay ? selectedMay?.TEN_MAY : null} />
                    </Item>
                  </Col>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.nguoi_tra_kq)}</span>
                    <Item label="" name={'NGUOI_TRA_KQ_ID'}>
                      <SelectAntd
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA}
                        options={dsBacSiRis?.map(obj => ({ label: obj.HO + " " + obj.TEN, value: obj.NHANSU_ID, key: obj.NHANSU_ID }))}
                        placeholder={i18n.t(languageKeys.common_Chon)}
                      />
                    </Item>
                  </Col>
                  <Col flex={"20%"}>
                    <span>{i18n.t(languageKeys.ngay_tra_kq)}</span>
                    <Item label="" name={fieldKetQua.NGAY_TRA_KQ}>
                      <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                  </Col>
                  <Col span={24}>
                    <span>{i18n.t(languageKeys.ghi_chu)}</span>
                    <Item label="" name={'GHI_CHU'}>
                      <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                  </Col>
                </Row>
              </div>
              {/* <Layout style={{ height: !openTTPhieu ? "calc(100% - 9px)" : "calc(100% - 45px)" }}> */}
              <Layout style={{ height: "100%" }}>
                <FormRISContent
                  ref={contentRef}
                  visible={visible}
                  classNames={style}
                  readOnly={currService && currService?.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA}
                  onChangeContent={onChangeContentFormNhap}
                  onClickButtonFile={onClickButtonFile}
                  currService={currService}
                />
                <SiderAnh
                  arrImgPrev={arrImgPrev}
                  currentService={currService}
                  onRemoveFile={handleRemoveFile}
                  removeAll={() => {
                    confirmRemoveAllRef.current.open();
                  }}
                  onClickImg={handleClickImg}
                  onClickEditImage={handleClickEditImage}
                  listImageUrl={listImageUrl}
                  seletedImage={seletedImage}
                  setSeletedImage={setSeletedImage}
                  setArrImgPrev={setArrImgPrev}
                  currServiceState={currService?.TRANG_THAI_KQ}
                  removeMultipleImageInDescription={removeMultipleImageInDescription}
                  updateEditedImagesInDescription={updateEditedImagesInDescription}
                  resultUrl={resultUrl}
                />
              </Layout>
            </Content>
          </Layout>
        </Form>

        <WebcamCapture ref={webcamRef} onTakePhoto={handleGetPhotoBase64} />
        <ModalImportImage ref={importRef} onFinish={handleFinishImport} />
        <ModalEditImage ref={editImageRef} onSubmitEditImage={handleSubmitEditImage} />
        <ModalPDF ref={pdfRef} />
        <ConfirmModal content={i18n.t(languageKeys.noti_ban_co_muon_xua_het_anh_khong)} cancelText={i18n.t(languageKeys.common_Huy)} onOk={removeAll} ref={confirmRemoveAllRef} danger />
        <FormDrawer
          visible={visible2}
          width={widthDrawerKeys.small}
          closable
          onClose={handleCloseDrawFile}
          onCancel={handleCloseDrawFile}
          className={style["form-container"]}
          closeIcon={false}
          maskClosable={false}
          title={i18n.t(languageKeys.file_dinh_kem)}
          back={true}
          newBackIcon={true}
          hiddenTopAction={true}
          footer={
            <>
              <Row justify="end" gutter={[16, 16]}>
                <Col>
                  <Button type="primary" ghost onClick={handleCloseDrawFile}>
                    {i18n.t(languageKeys.common_Thoat)}
                  </Button>
                </Col>
                <Col>
                  {currService?.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA && <Button type="primary" onClick={handleOkDrawFile}>
                    {i18n.t(languageKeys.common_Luu)}
                  </Button>}
                </Col>
              </Row>
            </>
          }
        >
          <div style={{ padding: "15px 20px" }}>
            <div className={style["form-draw"]}>
              <h2 className={style["form-header"]}>{i18n.t(languageKeys.thong_tin_file_dinh_kem)}</h2>
              <div className={style["form-content"]}>
                <Row gutter={10} justify="end">
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 20 }}>
                      <label>{i18n.t(languageKeys.tim_file)}</label>
                      <div>
                        <input id="upload-file" type="file" accept="application/pdf" onChange={handleSetUpload} style={{ display: "none" }} ref={fileRef} />
                        <label htmlFor="upload-file" style={{ display: "flex" }}>
                          {currService?.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA && <div className={style["button-svg"]}>
                            <Attachment />
                            {i18n.t(languageKeys.file_dinh_kem)}
                          </div>}
                        </label>
                      </div>
                    </div>
                    <Row gutter={[10, 10]} align="middle">
                      {fileList?.map((file, index) => {
                        return (
                          <Col span={24} key={nanoid(5)} className={style["item-file"]} style={{ cursor: "pointer" }} onClick={() => handleClickFile(file)}>
                            <div
                              className={style["item-file-1"]}
                              style={{ marginRight: "12px", cursor: "pointer" }}
                              onClick={() => handleClickFile(file)}
                            >
                              <FileCheck />
                            </div>
                            <span className={style["item-file-2"]}>{file.TEN_FILE}</span>
                            {currService?.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA && <CloseOutlined
                              item={file}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelFile(file);
                              }}
                            />}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </FormDrawer>

        <QlyVatTuRIS ref={vattuRef} phieu={phieu} currService={currService} />

      </Drawer>
    );
  }
);

export default memo(FormNhapKetQua);

const Topbar = memo(({ patientInfo, handleClose, vattuRef }) => {
  return (
    <>
      <Row className={style["header-title"]} justify="space-between">
        <Col >
          <Row gutter={20}>
            <Col className={style["title-drawer"]}>
              <NewBackIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
              <h2>{i18n.t(languageKeys.title_form_nhap_ket_qua)}</h2>
            </Col>

          </Row>
        </Col>
        <Col>
          <Row justify="end" gutter={[12, 12]}>
            <Col className={style["form__top--action"]}>
              <Row className={style["patient-title"]} justify="space-evenly">
                <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ten_benh_nhan)}:</Col>{" "}
                <Col className="blue-txt" style={{ display: "inline" }}>
                  {patientInfo?.TEN}
                </Col>
              </Row>
              <Row className={style["patient-title"]} justify="space-evenly">
                <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ma_benh_nhan)}: </Col>
                <Col>
                  <span>{patientInfo?.MA_BENH_NHAN}</span>
                </Col>
              </Row>
              <Row className={style["patient-title"]}>
                <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ma_CK)}: </Col>
                <Col>
                  <span>{patientInfo?.MA_CUOC_KHAM}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button type="primary" onClick={() => vattuRef.current?.open()}>
                {i18n.t(languageKeys.quan_ly_vat_tu_hoa_chat)}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
});

const fieldKetQua = {
  MA_PHIEU: "MA_PHIEU",
  NGAY_CHI_DINH: "NGAY_CHI_DINH",
  NGAY_TRA_KQ: "NGAY_TRA_KQ",
  THOI_GIAN_Y_LENH: "THOI_GIAN_Y_LENH",
  GIO_Y_LENH: "GIO_Y_LENH",
  NGUOI_THUC_HIEN: "NGUOI_THUC_HIEN",
  NOI_CHI_DINH: "NOI_CHI_DINH",
  NOI_THUC_HIEN: "NOI_THUC_HIEN",
  NGUOI_TRA_KQ: "NGUOI_TRA_KQ",
  CHAN_DOAN: "CHAN_DOAN",
  TRANG_THAI_PHIEU: "TRANG_THAI_PHIEU",
  MAY_THUC_HIEN: "MAY_THUC_HIEN",
  TEN_MAY_THUC_HIEN: "TEN_MAY_THUC_HIEN",
  THOI_GIAN_CHI_DINH: "THOI_GIAN_CHI_DINH",

  // NGUOI_THUC_HIEN_ID: "NGUOI_THUC_HIEN_ID",
  // NGUOI_TRA_KQ_ID: "NGUOI_TRA_KQ_ID",


};
