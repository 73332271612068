import React , {useState, useEffect} from "react";
import i18n, {languageKeys} from "../../../../../i18n";
import {Form, InputNumber, Input} from "antd";
import style from "./style.module.less"
import {fieldKhamBenh} from "../ThongTinKham";
import { HLog } from "../../../../../helpers";
import { keyFormKham } from "constants/data";
const HoiBenh = (
    {
        containerStyle = {},
        readOnly,
        form,
        isEditAllowed=false,
        roomTemplate,
    }
) => {
    useEffect(() => {
        HLog("HoiBenh useEffect...")
        //form.resetFields();
    }, []);
    
    return roomTemplate?.includes?.(keyFormKham.KHAM_DINH_DUONG) ? <FormHoiBenhDinhDuong
        containerStyle={containerStyle}
        readOnly={readOnly}
        isEditAllowed={isEditAllowed}
    /> : (
        <div style={containerStyle} className={style["form-container"]} >
            <Form.Item label={i18n.t(languageKeys.field_Ly_do_kham)} name={fieldKhamBenh.LY_DO_VAO_VIEN}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>

            <Form.Item label={i18n.t(languageKeys.field_Qua_trinh_benh_ly)} name={fieldKhamBenh.QUA_TRINH_BENH_LY}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>
            {/* <Form.Item label={i18n.t(languageKeys.trieu_chung_lam_sang)} name={fieldKhamBenh.TRIEU_CHUNG_LAM_SANG}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item> */}
            <Form.Item label={i18n.t(languageKeys.tien_sua_benh_ban_than)} name={fieldKhamBenh.TIEN_SU_BENH}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>

            <Form.Item label={i18n.t(languageKeys.tien_su_san_khoa)} name={fieldKhamBenh.TIEN_SU_SAN_KHOA}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>

            <Form.Item label={i18n.t(languageKeys.tien_su_di_ung)}  name={fieldKhamBenh.TIEN_SU_DI_UNG}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>

            <Form.Item label={i18n.t(languageKeys.tai_nan_thuong_tich)}  name={fieldKhamBenh.TAI_NAN_THUONG_TICH}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>

            <Form.Item label={i18n.t(languageKeys.tien_su_benh_gia_dinh)} name={fieldKhamBenh.TIEN_SU_GIA_DINH}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>

            <Form.Item label={i18n.t(languageKeys.tien_su_benh_khac)} name={fieldKhamBenh.TIEN_SU_BENH_KHAC}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>
        </div>
    )
}


const FormHoiBenhDinhDuong = ({
    containerStyle,
    readOnly,
    isEditAllowed
}) => {

    return (
        <div style={containerStyle} className={style["form-container"]} >
            <Form.Item label={i18n.t(languageKeys.field_ly_do_tu_van_dinh_duong)} name={fieldKhamBenh.LY_DO_VAO_VIEN}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}
                />
            </Form.Item>

            <Form.Item label={i18n.t(languageKeys.field_mot_ngay_an_dien_hinh_hien_nay)} name={fieldKhamBenh.CHE_DO_AN_UONG}>
                <Input.TextArea autoSize={{ minRows:4, maxRows:6 }} readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>
            <Form.Item label={i18n.t(languageKeys.field_mot_ngay_tap_va_hoat_dong_dien_hinh_hien_nay)} name={fieldKhamBenh.CHE_DO_LUYEN_TAP}>
                <Input.TextArea autoSize={{ minRows:4, maxRows:6 }} readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item>
        </div>
    )
}
export default React.memo(HoiBenh);
