import { Form, notification } from "antd";
import i18n, { languageKeys } from "../../../../../i18n";

export const useAntForm = () => {
  const [form] = Form.useForm();

  const submitForm = async () => {
    try {
      const values = await form.validateFields();

      console.log("ANTD FORM - SUBMIT", values);

      return values;
    } catch (error) {
      console.log("ANTD FORM - ERROR FIELDS", error);

      if (error?.errorFields?.[0]?.name?.toString() === "BAC_SI_ID") {
        notification.error({ message: i18n.t(languageKeys.vui_long_chon_bs_kham), placement: "bottomLeft" });
      } else {
        error?.errorFields?.forEach((error, index) => {
          if (index === 0) form.scrollToField(error?.name[0]);
          notification.error({ message: error.errors.join(","), placement: "bottomLeft", });
        });
      }

      throw { message: Error("ANTD FORM - ERROR FIELDS"), error: error }
    }
  };

  return { form, submitForm };
};
