
import { Button, Form, Input, InputNumber, Radio, Row } from "antd";
import React, { useMemo, useRef } from "react";
import { Select } from "../../../../../components";
import { HUONG_XU_TRI } from "../../../../../constants/keys";
import i18n, { languageKeys } from "../../../../../i18n";
import { fieldKhamBenh } from "../ThongTinKham";
import ToothDiagram from "./ToothDiagram";
import style from "./style.module.less";
import { NamePhongKhamMat, NamePhongRHM, NamePhongTMH, NamePhongTai } from "../constantsTTK";
import LieuTrinhDeXuat from "../../../../QuanLyGoiDichVu/GoiLieuTrinh/LieuTrinhDeXuat/LieuTrinhDeXuat";
import { useSelector } from "react-redux";
import { keyFormKham } from "constants/data";
import PhieuChuyenVien from "../popup/PhieuChuyenVien";


const data_xu_tri = Object.keys(HUONG_XU_TRI).map(item => ({
  key: item,
  lable: HUONG_XU_TRI[`${item}`]
}))

const ChanDoanXuTri = (
  {
    containerStyle = {},
    form,
    dsICD10Chinh = [],
    dsICD10Phu = [],
    dsBenhPhu = [],
    onSearchDsICD10Chinh = () => {},
    onSearchDsICD10Phu = () => {},
    onSelectICDPhu = () => {},
    onChuyenTabKeThuoc = {},

    onPressEnterThemICD = () => {},

    //
    dsYHCTChinh = [],
    dsYHCTPhu = [],
    dsBenhYHCTPhu = [],
    onSelectBenhYHCTPhu = () => {},
    onSearchDsYHCTChinh = () => {},
    onSearchDsYHCTPhu = () => {},
    onPressEnterThemYHCT = () => {},
    isNotRequired = false, //bỏ bắt buộc nhập chẩn đoán sơ bộ,
    requireChanDoan = true,
    isEditAlowed = false,
    phongChiDinh,
    isPopup = false,
    patientInfo,
    setDisabledTaiKham,
    setCollapsed = () => {},
  }
) => {

  const bundleRef = useRef()
  const transferRef = useRef()

  const userProfile = useSelector((state) => state.auth.user);

  const checkDongBoDonThuoc = useMemo(() => {
    try {
      return !!userProfile.config.DON_THUOC_CONG_DUOC
    } catch (error) {
      return false
    }
  }, [userProfile])

  // const ruleDongBoDonThuoc = [{
  //   required: checkDongBoDonThuoc,
  //   message: i18n.t(languageKeys.vui_long_nhap)
  // }]

  const XU_TRI = Form.useWatch(fieldKhamBenh.XU_TRI, form)

  const handleOpenLieuTrinh = () => {
    bundleRef.current?.open(patientInfo)
    form.setFieldValue(fieldKhamBenh.XU_TRI, "LIEU_TRINH")
  }

  const renderUI = useMemo(() => {
    // if (phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongKhamMat)) {
    if (phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_MAT)) {
      return (
        <>
          <p style={{ paddingBottom: 8 }}>
            <label>{i18n.t(languageKeys.eyesight_without_glasses)}</label>
          </p>
          <Row gutter={25}>
            <div className={style["form-eyes"]}>
              <Form.Item label={false} name={fieldKhamBenh.MAT_TRAI_K_KINH}>
                <Input
                  addonBefore={i18n.t(languageKeys.left_eye)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
              <Form.Item label={false} name={fieldKhamBenh.MAT_PHAI_K_KINH}>
                <Input
                  addonBefore={i18n.t(languageKeys.right_eye)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
            </div>
          </Row>
          <p style={{ paddingBottom: 8 }}>
            <label>{i18n.t(languageKeys.eyesight_with_glasses)}</label>
          </p>
          <Row gutter={25}>
            <div className={style["form-eyes"]}>
              <Form.Item label={false} name={fieldKhamBenh.MAT_TRAI_CO_KINH}>
                <Input
                  addonBefore={i18n.t(languageKeys.left_eye)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
              <Form.Item name={fieldKhamBenh.MAT_PHAI_CO_KINH}>
                <Input
                  addonBefore={i18n.t(languageKeys.right_eye)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
            </div>
          </Row>
        </>
      );
    }
    // if (phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTMH) || phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTai)) {
    if (phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_TAI_MUI_HONG)) {
      return (
        <>
          <p style={{ paddingBottom: 8 }}>
            <label>{i18n.t(languageKeys.left_ear)}</label>
          </p>
          <Row gutter={25}>
            <div className={style["form-eyes"]}>
              <Form.Item label={false} name={fieldKhamBenh.THUONG_TAI_TRAI}>
                <Input
                  addonBefore={i18n.t(languageKeys.speak_normally)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
              <Form.Item label={false} name={fieldKhamBenh.THI_THAM_TAI_TRAI}>
                <Input
                  addonBefore={i18n.t(languageKeys.speak_whisper)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
            </div>
          </Row>
          <p style={{ paddingBottom: 8 }}>
            <label>{i18n.t(languageKeys.right_ear)}</label>
          </p>
          <Row gutter={25}>
            <div className={style["form-eyes"]}>
              <Form.Item label={false} name={fieldKhamBenh.THUONG_TAI_PHAI}>
                <Input
                  addonBefore={i18n.t(languageKeys.speak_normally)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
              <Form.Item name={fieldKhamBenh.THI_THAM_TAI_PHAI}>
                <Input
                  addonBefore={i18n.t(languageKeys.speak_whisper)}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  disabled={!isEditAlowed}
                  controls={false}
                />
              </Form.Item>
            </div>
          </Row>
        </>
      );
    }

    // if (phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM)) {
    if (phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT)) {
      return (<ToothDiagram form={form} isPopup={isPopup} />);
    }

    return <></>;
  }, [phongChiDinh]);
  return (
    <div style={containerStyle} className={style["form-container"]}>
      {renderUI}
      {/* {phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM) ? (<> */}
      {phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT) ? (<>
        <label>
          {i18n.t(languageKeys.chan_doan_so_bo)}
          {/* <span style={{ color: "red" }}>*</span> */}
        </label>
        <div style={{ display: "flex", gap: 12 }} >
          <Form.Item
            name={fieldKhamBenh.HAM_TREN_SO_BO}
            style={{
              width: "50%",
            }}
            label={i18n.t(languageKeys.upper_jaw)}
          >
            <Input

              placeholder={i18n.t(languageKeys.common_Nhap)}
            />
          </Form.Item>
          <Form.Item
            name={fieldKhamBenh.HAM_DUOI_SO_BO}
            style={{
              width: "50%",
            }}
            label={i18n.t(languageKeys.lower_jaw)}
          >
            <Input
              placeholder={i18n.t(languageKeys.common_Nhap)}
            />
          </Form.Item>
        </div></>) : (<Form.Item
          label={i18n.t(languageKeys.chan_doan_so_bo)}
          name={fieldKhamBenh.CHAN_DOAN_LAM_SANG}
        // rules={[
        //   {
        //     required: !isNotRequired,
        //     message: i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo),
        //   },
        // ]}
        >
          <Input.TextArea autoSize disabled={!isEditAlowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
        </Form.Item>)}

      <Form.Item
        label={i18n.t(languageKeys.icd_benh_chinh)}
        name={fieldKhamBenh.benh_chinh}
        rules={[
          {
            // required: checkDongBoDonThuoc,
            required: false,
            message: "Vui lòng nhập chẩn đoán bệnh chính",
          },
        ]}
      >
        <Select
          dataSource={dsICD10Chinh}
          customTitle={(item) => item.MA_ICD + "-" + item.TEN_ICD}
          onSelect={(value) => form.setFields([{ name: fieldKhamBenh.benh_chinh, errors: [] }])}
          showSearch
          onSearch={onSearchDsICD10Chinh}
          disabled={!isEditAlowed}
          onDropdownVisibleChange={(bool) => bool && onSearchDsICD10Chinh("")}
        />
      </Form.Item>
      {/* {phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongKhamMat) || phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM) || phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTMH) || phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTai) ? <></> : ( */}
      {phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_MAT) || phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT) || phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_TAI_MUI_HONG) ? <></> : (
        <Form.Item label={i18n.t(languageKeys.icd_yhct_chinh)} name={fieldKhamBenh.benh_yhct_chinh}
        // rules={ruleDongBoDonThuoc}
        >
          <Select
            dataSource={dsYHCTChinh}
            customTitle={(item) => item.MA_ICD + "-" + item.TEN_ICD}
            onSelect={(value) => form.setFields([{ name: fieldKhamBenh.benh_yhct_chinh, errors: [] }])}
            showSearch
            onSearch={onSearchDsYHCTChinh}
            disabled={!isEditAlowed}
            onDropdownVisibleChange={(bool) => bool && onSearchDsYHCTChinh("")}
          />
        </Form.Item>
      )}

      <Form.Item label={i18n.t(languageKeys.icd_benh_kem_theo)} name={fieldKhamBenh.benh_phu}>
        <Select
          dataSource={dsICD10Phu}
          multiple
          customTitle={(item) => (!!item.MA_ICD ? item.MA_ICD + "-" + item.TEN_ICD : "" + item.TEN_ICD)}
          onChange={(value) => {
            onSelectICDPhu(value);
            form.setFields([
              {
                name: fieldKhamBenh.benh_phu,
                errors: [],
              },
            ]);
          }}
          selectedList={dsBenhPhu}
          setSelectedList={(value) => {
            onSelectICDPhu(value);
            form.setFields([{ name: fieldKhamBenh.benh_phu, value: value }]);
          }}
          showSearch
          onSearch={onSearchDsICD10Phu}
          tagCount={0}
          disabled={!isEditAlowed}
          onDropdownVisibleChange={(bool) => bool && onSearchDsICD10Phu("")}
          onPressEnter={onPressEnterThemICD}
        />
      </Form.Item>

      {/* {(phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongKhamMat) || phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM)) || phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTMH) || phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTai) ? <></> : ( */}
      {(phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_MAT) || phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT) || phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_TAI_MUI_HONG)) ? <></> : (
        <Form.Item label={i18n.t(languageKeys.idc_yhct_kem)} name={fieldKhamBenh.benh_yhct_phu}>
          <Select
            dataSource={dsYHCTPhu}
            multiple
            customTitle={(item) => (!!item.MA_ICD ? item.MA_ICD + "-" + item.TEN_ICD : "" + item.TEN_ICD)}
            onChange={(value) => {
              onSelectBenhYHCTPhu(value);
              form.setFields([
                {
                  name: fieldKhamBenh.benh_yhct_phu,
                  errors: [],
                },
              ]);
            }}
            selectedList={dsBenhYHCTPhu}
            setSelectedList={(value) => {
              onSelectBenhYHCTPhu(value);
              form.setFields([{ name: fieldKhamBenh.benh_yhct_phu, value: value }]);
            }}
            showSearch
            onSearch={onSearchDsYHCTPhu}
            tagCount={0}
            disabled={!isEditAlowed}
            onDropdownVisibleChange={(bool) => bool && onSearchDsYHCTPhu("")}
            onPressEnter={onPressEnterThemYHCT}
          />
        </Form.Item>
      )}
      {/* {phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM) ? (<Form.Item */}
      {phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT) ? (<Form.Item
        label={
          <label>
            {i18n.t(languageKeys.chan_doan_xac_dinh)}
          </label>
        }
      >
        <Input.Group style={{ display: "flex", gap: 12 }} >
          <Form.Item
            name={fieldKhamBenh.HAM_TREN_XAC_DINH}
            style={{
              width: "50%",
            }}
            label={i18n.t(languageKeys.upper_jaw)}
          >
            <Input

              placeholder={i18n.t(languageKeys.common_Nhap)}
            />
          </Form.Item>
          <Form.Item
            name={fieldKhamBenh.HAM_DUOI_XAC_DINH}
            style={{
              width: "50%",
            }}
            label={i18n.t(languageKeys.lower_jaw)}
          >
            <Input
              placeholder={i18n.t(languageKeys.common_Nhap)}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>) : (<Form.Item label={i18n.t(languageKeys.chan_doan_xac_dinh)} name={fieldKhamBenh.CHAN_DOAN_XAC_DINH}>
        <Input.TextArea autoSize disabled={!isEditAlowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>)}


      <div style={{ display: 'flex', alignItems: 'end' }}>
        <Form.Item label={i18n.t(languageKeys.huong_xu_tri)} name={fieldKhamBenh.XU_TRI} style={{ width: '100%' }}>
          <Select
            dataSource={data_xu_tri}
            titleKey="lable"
            valueKey="key"
            onSelect={(value) => {
              // if (!chan_doan_so_bo) {
              //   notification.error({ message: i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo), placement: "bottomLeft" });
              //   form.setFields([
              //     {
              //       name: fieldKhamBenh.XU_TRI,
              //       value: null,
              //     },
              //     {
              //       name: fieldKhamBenh.CHAN_DOAN_LAM_SANG,
              //       errors: [i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo)],
              //     },
              //   ]);
              //   return;
              // }
              if (value === "KE_DON") {
                onChuyenTabKeThuoc();
                setCollapsed(true)
              }
              if (value === "HEN_TAI_KHAM") {
                form.setFieldValue(fieldKhamBenh.TAI_KHAM, 1)
                setDisabledTaiKham(false)
              }
            }}
            disabled={!isEditAlowed}
          />
        </Form.Item>
        {XU_TRI === 'CHUYEN_VIEN'
          ? (
            <Button type='primary' style={{ marginBottom: 8 }} onClick={() => transferRef.current?.open(patientInfo)} disabled={!patientInfo || !isEditAlowed}>
              {i18n.t(languageKeys.phieu_chuyen_vien)}
            </Button>
          )
          : (
            <Button type='primary' style={{ marginBottom: 8 }} onClick={handleOpenLieuTrinh} disabled={!patientInfo || !isEditAlowed}>
              {i18n.t(languageKeys.lieu_trinh_de_xuat)}
            </Button>
          )
        }
      </div>

      <Form.Item label={i18n.t(languageKeys.loi_dan)} name={fieldKhamBenh.LOI_DAN}>
        <Input.TextArea autoSize disabled={!isEditAlowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>

      <LieuTrinhDeXuat ref={bundleRef} />

      <PhieuChuyenVien ref={transferRef} />

    </div >
  );
}

// Form chẩn đoán nếu là phòng dinh dưỡng
export const FormChanDoanXuTriDinhDuong = React.memo(({ containerStyle, readOnly, isEditAllowed }) => {

  return (
    <>
      <div style={containerStyle} className={style["form-container"]}>
      <Form.Item label={i18n.t(languageKeys.don_dinh_duong)} name={fieldKhamBenh.DON_DINH_DUONG}>
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 6 }}
          readOnly={readOnly}
          disabled={!isEditAllowed}
          placeholder={i18n.t(languageKeys.common_Nhap)}
        />
      </Form.Item>

      <Form.Item label={i18n.t(languageKeys.don_tap_luyen)} name={fieldKhamBenh.DON_TAP_LUYEN}>
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 6 }}
          readOnly={readOnly}
          disabled={!isEditAllowed}
          placeholder={i18n.t(languageKeys.common_Nhap)}
        />
      </Form.Item>
      <Form.Item label={i18n.t(languageKeys.luu_y_chung)} name={fieldKhamBenh.LOI_DAN}>
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 6 }}
          readOnly={readOnly}
          disabled={!isEditAllowed}
          placeholder={i18n.t(languageKeys.common_Nhap)}
        />
      </Form.Item>

      <Form.Item label={i18n.t(languageKeys.muc_do_cam_ket_cua_khach_hang)} name={fieldKhamBenh.MUC_DO_CAM_KET}>
        <Radio.Group>
          <Radio className={style['rating-items']} value={1}><span className={style['rating-value']}>1</span></Radio>
          <Radio className={style['rating-items']} value={2}><span className={style['rating-value']}>2</span></Radio>
          <Radio className={style['rating-items']} value={3}><span className={style['rating-value']}>3</span></Radio>
          <Radio className={style['rating-items']} value={4}><span className={style['rating-value']}>4</span></Radio>
          <Radio className={style['rating-items']} value={5}><span className={style['rating-value']}>5</span></Radio>
        </Radio.Group>
      </Form.Item>
      <div style={{height:22}}/>
      </div>
    </>
  );
});


export default React.memo(ChanDoanXuTri);


