import React, { useState, useImperativeHandle, useEffect, useMemo, useRef } from 'react'
import { Row, Col, Button, Modal, Upload, message, notification } from "antd";
import style from './style.module.less';
import { CameraOutlined, CloseOutlined } from '@ant-design/icons';
import { compressionImage, getBase64, resizeFile, rid } from '../../../../helpers';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor'
import i18n, { languageKeys } from '../../../../i18n';

const { Dragger } = Upload;

const VIEWS = {
    UPLOAD: 'UPLOAD',
    EDIT: 'EDIT',
    PREVIEW: 'PREVIEW'

}


const ModalImportImage = React.forwardRef(({
    onFinish = () => {},
}, ref) => {
    const imageEditorRef = useRef();
    const [open, setOpen] = useState(false);
    const [listImages, setListImages] = useState([]);
    const [currentImage, setCurrentImage] = useState();
    const [view, setView] = useState(VIEWS.UPLOAD);

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
        close : handleClose,

    }));

    const handleClose = () => {
        setOpen(false);
        setListImages([]);
        currentImage && setCurrentImage();
        setView(VIEWS.UPLOAD)
    }

    const handleUpload = async (file, fileList) => {
        if (listImages.length === 8) {
            notification.error({message : i18n.t(languageKeys.so_luong_anh_toi_da)});
            return;
        }
        let total_current = listImages.length;
        let total_image_free = 8 - total_current;
        let array_imported = fileList.slice(0, total_image_free);
        let array_img = [];
        let errorCheckSize = false;
        for(let item of array_imported) {
            // //array_img.push(item);
            // if (file.size/1024/1024 > window.FILE_MAX_SIZE_MB) {
            //    errorCheckSize = true;
            // }else{
                array_img.push(item);
            // }
        }
        // if (errorCheckSize) {
        //     notification.error({
        //         message: `Kích thước ảnh nhỏ hơn ${window.FILE_MAX_SIZE_MB}MB`,
        //         placement: "bottomLeft",
        //     });
        // }
        // const list_img_compressed = await compressionArrayImage(array_img);
        // console.log("list_img_compressed",list_img_compressed)
        let list_imge = await convertListImageToBase64(array_img);
        const added = list_imge.map(item => ({image : item, id : rid()}))
        const new_list = listImages.concat(added)
        setListImages(new_list);
    }

    const compressionArrayImage = async (data) => {
        try{
            const promises = data.map(async image => {
                const promise = await compressionImage(image)
                return promise
              })
              const result = await Promise.all(promises)
              return result
        }catch(error){
           console.log("error", error)
        }
    }
    const convertListImageToBase64 = async (data) =>  {
        try {
            const promises = data.map(async image => {
                const images = await resizeFile(image);
                const promise = await getBase64(images)
                return promise
              })
              const result = await Promise.all(promises)
              return result
        } catch (error) {
            console.log(error)
        }
      }

    const handleDeleteImage = (id) => {
        setListImages(prev => {
            return prev.filter(item => item.id != id);
        })
        if (currentImage && currentImage.id === id) {
            setView(VIEWS.UPLOAD);
            setCurrentImage()
        }
    }

    const handleChooseImage = (item) => {
        setCurrentImage(item);
        setView(VIEWS.PREVIEW)
    }

    const handleContinueUpload = () => {
        setView(VIEWS.UPLOAD)
    }

    const handleOpenEdit = () => {
        setView(VIEWS.EDIT);
    }


    const handleSaveImage = () => {
        const canvas = document.querySelector(".lower-canvas");
        const imageData = canvas.toDataURL("image/jpeg");
        setListImages(prev => {
            return prev.map(item => {
                if (item.id === currentImage.id) {

                    let data = { ...item, image: imageData }
                    setCurrentImage(data);
                    return data
                }
                return item
            })
        });
        setView(VIEWS.PREVIEW);
    }

    const handleFinish = () => {
        onFinish(listImages);
    }

    const renderFooter = () => {
        return (
            <Row justify={"center"} align="middle">
                <Button onClick={handleContinueUpload} disabled={listImages.length >= 6} type='primary'>
                    {i18n.t(languageKeys.tiep_tuc_tai_anh_len)}
                </Button>
                <Button onClick={handleOpenEdit} disabled={!currentImage} type='primary'>
                    {i18n.t(languageKeys.chinh_sua_anh)}
                </Button>
                <Button onClick={handleSaveImage} disabled={view != VIEWS.EDIT} type='primary'>
                    {i18n.t(languageKeys.save_image)}
                </Button>
                <Button onClick={handleFinish} disabled={listImages.length == 0} type='primary'>
                    {i18n.t(languageKeys.chon_anh)}
                </Button>
                <Button ghost danger type='primary' onClick={handleClose}>
                    {i18n.t(languageKeys.huy)}
                </Button>
            </Row>
        )
    }

    return (
        <Modal
            open={open}
            centered
            title={i18n.t(languageKeys.chon_anh)}
            footer={renderFooter()}
            onCancel={handleClose}
            width={'1200px'}
        >
            <div className={style['container_import']}>
                <div className={style['upload-container']}>
                    {view == VIEWS.UPLOAD && <Dragger
                        name='file'
                        multiple={true}
                        beforeUpload={handleUpload}
                        type="file"
                        accept="image/*"
                        className={style['input-updload-image']}
                    >
                        <p className="ant-upload-text">{i18n.t(languageKeys.tai_len_hinh_anh)}</p>
                    </Dragger>}

                    {
                        view == VIEWS.PREVIEW &&
                        <div style={{ width: "100%", height: "100%" }}>
                            <img src={currentImage?.image} className={style['current-image']} />
                        </div>
                    }
                    {
                        view == VIEWS.EDIT &&
                        <ImageEditor
                            ref={imageEditorRef}
                            includeUI={{
                                loadImage: {
                                    path: currentImage?.image,
                                    name: 'SampleImage',
                                },
                                uiSize: {
                                    width: '850px',
                                    height: '450px',
                                },
                                menuBarPosition: 'bottom',
                            }}
                            cssMaxHeight={550}
                            cssMaxWidth={500}
                            selectionStyle={{
                                cornerSize: 20,
                                rotatingPointOffset: 70,
                            }}
                            usageStatistics={true}

                        />
                    }
                </div>
                <div className={style['list-images']}>
                    <Row wrap={true} justify="space-between" align={"center"} gutter={[10, 10]}>
                        {listImages.map((item, index) => {
                            return (
                                <Col key={index} span={12}>
                                    <div className={style['default-image']}>
                                        <img src={item.image} className={style['image-current']} onClick={() => handleChooseImage(item)} />
                                        <div className={style['delete-icon']} onClick={() => handleDeleteImage(item.id)}>
                                            <CloseOutlined style={{ fontSize: 8, transform: "translateY(-2px)" }} />
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}

                        {listImages.length < 8 && Array(8 - listImages.length).fill(null).map((item, index) => {
                            return <Col span={12} key={index}>
                                <div className={style['default-image']}>
                                    <CameraOutlined style={{ color: "#999", transform: "scale(1.2)", margin: "auto" }} />
                                </div>
                            </Col>
                        })}
                    </Row>
                </div>
            </div>
        </Modal>
    )
})

export default ModalImportImage