import { Button, Row, Tooltip } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import { Checked, UnCheck } from "assets/svg";
import { convertDateToValue } from "helpers";

export const trang_thai_th = {
  CHO_THUC_HIEN: "CHO_THUC_HIEN",
  DA_THUC_HIEN: "DA_THUC_HIEN",
  HOAN_THANH: "HOAN_THANH",
  DANG_THUC_HIEN: "DANG_THUC_HIEN",
};

export const trang_thai_tt = {
  CHUA_TT: "CHUA_TT",
  DA_TT: "DA_TT",
};

export const fields = {
  STT: "STT",
  TEN_DICHVU: "TEN_DICHVU",
  SO_LUONG: "SO_LUONG",
  MO_TA_CHI_TIET: "MO_TA_CHI_TIET",
  TT_VIEN_CHINH: "TT_VIEN_CHINH",
  NGAY_BAT_DAU: "NGAY_BAT_DAU",
  TRANG_THAI_TH: "TRANG_THAI_TH",
  TRANG_THAI_TT: "TRANG_THAI_TT",
};

const ellipsisProps = {
  ellipsis: true,
  render: (data) => (
    <Tooltip placement="topLeft" title={data} zIndex={1}>
      {data === "null" || data === "undefined" || !data ? "" : data}
    </Tooltip>
  ),
};

export const columns = [
  {
    title: i18n.t(languageKeys.stt),
    width: 40,
    dataIndex: fields.STT,
    align: "center",
  },
  {
    title: i18n.t(languageKeys.field_Ten_dich_vu),
    width: 130,
    dataIndex: fields.TEN_DICHVU,
    ...ellipsisProps,
  },
  {
    title: i18n.t(languageKeys.sl),
    width: 75,
    dataIndex: fields.SO_LUONG,
  },
  {
    title: i18n.t(languageKeys.mo_ta),
    width: 100,
    dataIndex: fields.MO_TA_CHI_TIET,
    ...ellipsisProps,
    // render: (MO_TA) => removeTags(MO_TA),
  },
  {
    title: i18n.t(languageKeys.nguoi_thuc_hien),
    width: 100,
    dataIndex: fields.TT_VIEN_CHINH,
    ...ellipsisProps,
  },
  {
    title: i18n.t(languageKeys.thoi_gian_thuc_hien),
    width: 110,
    dataIndex: fields.NGAY_BAT_DAU,
    ...ellipsisProps,
    render: (NGAY_BAT_DAU, record) => (NGAY_BAT_DAU ? record.GIO_BAT_DAU + " - " + convertDateToValue(record.NGAY_BAT_DAU) : ""),
  },
  {
    title: i18n.t(languageKeys.status_Da_thanh_toan),
    width: 60,
    align: "center",
    dataIndex: fields.TRANG_THAI_TT,
    render: (TRANG_THAI_TT) =>
      TRANG_THAI_TT === trang_thai_tt.DA_TT ? (
        <Tooltip title={i18n.t(languageKeys.status_Da_thanh_toan)}>
          <Checked />
        </Tooltip>
      ) : (
        <Tooltip title={i18n.t(languageKeys.status_Chua_thanh_toan)}>
          <UnCheck />
        </Tooltip>
      ),
  },
  {
    title: i18n.t(languageKeys.da_TH),
    width: 85,
    align: "center",
    dataIndex: fields.TRANG_THAI_TH,
    render: (TRANG_THAI_TH) =>
      TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN ? (
        <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
          <Checked />
        </Tooltip>
      ) : (
        <Tooltip title={i18n.t(languageKeys.chua_thuc_hien)}>
          <UnCheck />
        </Tooltip>
      ),
  },
];

export const thaoTacColumns = ({ handleHuyTra, handleChuyenTrangThaiDichVu, handleInTuongTrinh, onDelete, handleNhapThongTin, handleRowClick, checkPermissions }) => {
  return [
    {
      width: 190,
      align: "center",
      render: (_, record) => {
        let TRANG_THAI;
        const checkChoTH = record.TRANG_THAI_TH === trang_thai_th.CHO_THUC_HIEN;
        const checkDaTH = record.TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN;
        const checkDangTH = record.TRANG_THAI_TH === trang_thai_th.DANG_THUC_HIEN;
        const text =
          (checkChoTH && `${i18n.t(languageKeys.thuc_hien)} (Ctrl + H)`) ||
          (checkDaTH && `${i18n.t(languageKeys.xem_tuong_trinh)}`) ||
          (checkDangTH && `${i18n.t(languageKeys.huy_thuc_hien)}`);
        if (checkChoTH) {
          TRANG_THAI = trang_thai_th.DANG_THUC_HIEN;
        }
        if (checkDangTH) {
          TRANG_THAI = trang_thai_th.CHO_THUC_HIEN;
        }
        return (
          <>
            {(checkDangTH && (
              <Row style={{ gap: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Tooltip title={checkPermissions.HUY ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                  <Button
                    style={{ width: 75 }}
                    type="primary"
                    ghost
                    danger
                    onClick={(e) => {
                      e.stopPropagation();
                      checkDangTH && handleChuyenTrangThaiDichVu(record, TRANG_THAI);
                    }}
                    disabled={!checkPermissions.HUY}
                  >
                    {text}
                  </Button>
                </Tooltip>
                <Tooltip title={checkPermissions.THUC_HIEN_TRA_KQ ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                  <Button
                    style={{ width: 75 }}
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(record);
                    }}
                    disabled={!checkPermissions.THUC_HIEN_TRA_KQ}
                  >
                    {i18n.t(languageKeys.tra_kq)}
                  </Button>
                </Tooltip>
              </Row>
            )) ||
              (checkChoTH && (
                <Tooltip title={checkPermissions.THUC_HIEN ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                  <Button
                    style={{ width: 150 }}
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      checkChoTH && handleChuyenTrangThaiDichVu(record, TRANG_THAI);
                    }}
                    disabled={!checkPermissions.THUC_HIEN}
                  >
                    {text}
                  </Button>
                </Tooltip>
              )) ||
              (checkDaTH && (
                <span style={{ display: "flex", gap: 2, justifyContent: "center" }}>
                  {handleHuyTra && (
                    <Tooltip title={checkPermissions.HUY ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                      <Button
                        style={{ width: 150 }}
                        className="ant-btn-dangerous"
                        type="dangerous"
                        onClick={(e) => {
                          e.stopPropagation();
                          checkDaTH && handleHuyTra(record);
                        }}
                        disabled={!checkPermissions.HUY}
                      >
                        {i18n.t(languageKeys.huy_tra)}
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title={checkPermissions.XEM_TT ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                    <Button
                      style={{ width: 150 }}
                      type="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        checkDaTH && handleInTuongTrinh(record);
                      }}
                      disabled={!checkPermissions.XEM_TT}
                    >
                      {text}
                    </Button>
                  </Tooltip>
                </span>
              ))}
          </>
        );
      },
    },
  ];
};

export const fieldThongTinThuThuat = {
  BENH_KEM_THEO: "BENH_KEM_THEO",
  CHAN_DOAN: "CHAN_DOAN",
  CACH_THUC_TT: "CACH_THUC_TT",
  PHUONG_PHAP_TT: "PHUONG_PHAP_TT",
  LOAI_TT: "LOAI_TT",
  TT_VIEN_CHINH: "TT_VIEN_CHINH",
  TT_PHU_MOT: "TT_PHU_MOT",
  TT_PHU_HAI: "TT_PHU_HAI",
  TT_PHU_BA: "TT_PHU_BA",
  DUNG_CU_VIEN: "DUNG_CU_VIEN",
  GM_CHINH: "GM_CHINH",
  GM_PHU: "GM_PHU",
  GIUP_VIEC: "GIUP_VIEC",
  NGAY_TAO: "NGAY_TAO",
  GIO_TAO: "GIO_TAO",
  GIO_BAT_DAU: "GIO_BAT_DAU",
  NGAY_BAT_DAU: "NGAY_BAT_DAU",
  GIO_KET_THUC: "GIO_KET_THUC",
  NGAY_KET_THUC: "NGAY_KET_THUC",
  MO_TA_CHI_TIET: "MO_TA_CHI_TIET",
  HINH_ANH_TT: "HINH_ANH_TT",
  BENH_NHAN_ID: "BENH_NHAN_ID",
  BENH_VIEN_ID: "BENH_VIEN_ID",
  LICH_KHAM_ID: "LICH_KHAM_ID",
  LUOT_DIEU_TRI_ID: "LUOT_DIEU_TRI_ID",
  DICH_VU_ID: "DICH_VU_ID",
  PHONG_KHAM_ID: "PHONG_KHAM_ID",
  PHONG_CHI_DINH_ID: "PHONG_CHI_DINH_ID",
  LUU_MAU: "LUU_MAU",
  MA_MAU: "MA_MAU",
  TEN_MAU: "TEN_MAU",
  TT_VIEN_CHINH_ID: "TT_VIEN_CHINH_ID",
};
export const fieldTTTTValid = {
  BENH_KEM_THEO: "BENH_KEM_THEO",
  CHAN_DOAN: "CHAN_DOAN",
  CACH_THUC_TT: "CACH_THUC_TT",
  PHUONG_PHAP_TT: "PHUONG_PHAP_TT",
  LOAI_TT: "LOAI_TT",
  TT_VIEN_CHINH: "TT_VIEN_CHINH",
  TT_PHU_MOT: "TT_PHU_MOT",
  TT_PHU_HAI: "TT_PHU_HAI",
  TT_PHU_BA: "TT_PHU_BA",
  DUNG_CU_VIEN: "DUNG_CU_VIEN",
  GM_CHINH: "GM_CHINH",
  GM_PHU: "GM_PHU",
  GIUP_VIEC: "GIUP_VIEC",
  GIO_BAT_DAU: "GIO_BAT_DAU",
  NGAY_BAT_DAU: "NGAY_BAT_DAU",
  GIO_KET_THUC: "GIO_KET_THUC",
  NGAY_KET_THUC: "NGAY_KET_THUC",
  MO_TA_CHI_TIET: "MO_TA_CHI_TIET",
  HINH_ANH_TT: "HINH_ANH_TT",
};
