/* eslint-disable react/jsx-pascal-case */
import { memo, useMemo, useRef } from "react";
import style from "./table.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { Dropdown, Input, InputNumber, Table, Typography } from "antd";
import cn from "classnames";
import { formatNumberToPrice, getFullName, hasDuplicateValues, locdau, rid } from "../../../../../helpers";
import { useState } from "react";
import { useEffect } from "react";
import { CaretDownOutlined, CaretRightOutlined, DownOutlined } from "@ant-design/icons";
import { ActionButton, Table as TableLocal, Select } from "../../../../../components";
import moment from "moment";
import { showDeleteBtn } from "../../helpers";
import { Check_box, Uncheckbox } from "../../../../../assets/svg";
import { TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI } from "../../../../../constants/keys";
import { useSelector } from "react-redux";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { tableLoadingState } from "ducks/slices/loadingSlice";
import SelectChangeTarget from "components_v2/SelectChangeTarget/SelectChangeTarget";
import SelectPhongThHien from "pages/QuanLyGoiDichVu/GoiLieuTrinh/ChiDinhDvGoi/SelectPhongThHien";

const TablekhamDv = ({
    dsDvClsDaChon = [],
    setDsDvClsDaChon = () => {},
    dsDvKhamBenhDaChon = [],
    setDsDvKhamBenhDaChon = () => {},
    readOnly = false,
    isCreateNew = true,
    onPrint = () => {},
    scheduleInfo,
    disableDichVu,
    disableIn,
    hightlighter,
    form,
    setExpandedRow,
    expandedRow,
}) => {
    const syncTargetRef = useRef()

    const { todayDoctors, personnel } = useSelector(getSharedData);
    const loading = useSelector(tableLoadingState)

    const forceRender = useState();

    const daKham = (trang_thai) => {
        return trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG || trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH;
    };

    const dangKham = (trang_thai) => {
        return trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG || trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH || trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM;
    };

    useEffect(() => {
        if (isCreateNew && todayDoctors) {
            if (dsDvClsDaChon.length) {
                setDsDvClsDaChon(dsDvClsDaChon.map(item => ({
                    ...item,
                    BAC_SI: todayDoctors.find(i => i.PHONG_ID === item.PHONG_THUC_HIEN_ID)
                })))
            }
            if (dsDvKhamBenhDaChon.length) {
                setDsDvKhamBenhDaChon(dsDvKhamBenhDaChon.map(item => ({
                    ...item,
                    BAC_SI: todayDoctors.find(i => i.PHONG_ID === item.PHONG_THUC_HIEN_ID)
                })))
            }
        }
    }, [todayDoctors])

    const columns = [
        {
            title: i18n.t(languageKeys.field_Ma_dich_vu),
            dataIndex: "MA_DICHVU",
            width: 120,
        },
        {
            title: i18n.t(languageKeys.field_Ten_dich_vu),
            dataIndex: "TEN_DICHVU",
            width: "250px",
        },
        {
            title: i18n.t(languageKeys.cate_doi_tuong),
            dataIndex: "DM_DOI_TUONG_ID",
            width: 150,
            render: (id, record) => (
                !record.DV_KEM_THEO_ID
                    ? <SelectChangeTarget
                        id={id}
                        record={record}
                        syncTargetRef={syncTargetRef}
                        handleChangeTarget={handleChangeTarget}
                        dataSource={dataSource}
                        readOnly={daKham(record.TRANG_THAI) || record.THANH_TOAN === 1 || record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN"}
                    />
                    : <></>
            ),
        },
        {
            title: i18n.t(languageKeys.field_don_gia) + " (đ)",
            dataIndex: "GIA_DICH_VU",
            width: 140,
            render: (GIA, record) => !record.DV_KEM_THEO_ID ? (
                <InputNumber
                    onChange={(value) => handleChangeInput(value, record, 'GIA_DICH_VU')}
                    value={GIA}
                    className={style['exemptions-input']}
                    placeholder={i18n.t(languageKeys.common_Nhap)}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={"đ"}
                    size="middle"
                    readOnly={!Boolean(record.CHO_PHEP_SUA_GIA_DICH_VU) || daKham(record.TRANG_THAI) || record.THANH_TOAN === 1 || record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN"}
                    min={0}
                />
            ) : '-',
        },
        {
            title: i18n.t(languageKeys.field_So_luong),
            dataIndex: "SO_LUONG",
            width: 75,
            render: (data, record) => !record.DV_KEM_THEO_ID ? (
                <InputNumber
                    min={1}
                    value={data}
                    onChange={(value) => handleChangeInput(value, record, 'SO_LUONG')}
                    onBlur={(e) => !e.target.value && handleChangeInput(1, record, 'SO_LUONG')}
                    size="middle"
                    readOnly={readOnly && record.old}
                    style={{ width: 65, height: 32 }}
                />
            ) : '-',
        },
        {
            title: i18n.t(languageKeys.tong_tien) + " (đ)",
            ellipsis: true,
            dataIndex: "TONG_TIEN",
            width: 125,
            render: (_, record) => !record.DV_KEM_THEO_ID ? formatNumberToPrice(record.GIA_DICH_VU * record.SO_LUONG) : '-',
        },
        {
            title: i18n.t(languageKeys.field_Mien_giam),
            dataIndex: "MIEN_GIAM_TIEP_DON",
            width: 140,
            render: (value, record) => (
                !record?.DV_KEM_THEO_ID
                    ? <InputNumber
                        onChange={(value) => handleChangeInput(value, record, 'MIEN_GIAM_TIEP_DON')}
                        value={value}
                        className={style['exemptions-input']}
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        // parser={value => parseFloat(value.replace(/\./g, ''))}
                        addonAfter={value > 100 ? "đ" : "%"}
                        size="middle"
                        readOnly={(readOnly && record.old) || !!record.THANH_TOAN}
                        min={0}
                        max={record.THANH_TIEN}
                    />
                    : <></>
            ),
        },
        {
            title: i18n.t(languageKeys.vien_phi_thanh_tien) + " (đ)",
            ellipsis: true,
            dataIndex: "THANH_TIEN",
            width: 125,
            render: (_, record) => !record.DV_KEM_THEO_ID
                ? formatNumberToPrice(record.SO_LUONG * record.GIA_DICH_VU - (
                    record.MIEN_GIAM_TIEP_DON
                        ? record.MIEN_GIAM_TIEP_DON > 100
                            ? record.MIEN_GIAM_TIEP_DON
                            : (record.SO_LUONG * record.GIA_DICH_VU / 100 * record.MIEN_GIAM_TIEP_DON)
                        : 0))
                : '-',
        },
        {
            title: i18n.t(languageKeys.field_Phong_thuc_hien),
            ellipsis: true,
            dataIndex: "PHONG_THUC_HIEN_ID",
            width: "250px",
            render: (value, record) => (
                !record.children
                    ? <SelectPhongThHien
                        value={value}
                        record={record}
                        onSelect={handleSelectPhong}
                        readOnly={disableDichVu}
                    />
                    : <></>
            ),
        },
        {
            title: i18n.t(languageKeys.nguoi_thuc_hien),
            dataIndex: "BAC_SI",
            width: 160,
            render: (data, record) => {
                if (record && !record.DV_KEM_THEO?.length) {
                    return <Typography.Text className="navy-txt">
                        {!!data && Object.keys(data).length === 2 ? data?.TEN_BAC_SI : getFullName(data)}
                    </Typography.Text>;
                } else {
                    return <></>;
                }
            },
        },
        {
            title: i18n.t(languageKeys.noi_thuc_hien),
            ellipsis: true,
            dataIndex: "NOI_THUC_HIEN_XN",
            width: 175,
            render: (data, record) => (
                record?.LIST_NOI_THUC_HIEN_XN?.length > 0
                    ? <NoiThucHienXn
                        noiThucHienXn={data}
                        listNoiThucHienXn={record?.LIST_NOI_THUC_HIEN_XN}
                        dichvu={record}
                        handleSelectNoiThXn={handleSelectNoiThXn}
                        readOnly={disableDichVu}
                        scheduleInfo={scheduleInfo}
                    />
                    : <></>
            ),
        },
        {
            title: i18n.t(languageKeys.nguoi_tao),
            dataIndex: "NGUOI_TAO",
            width: 180,
            render: (data, record) => !record.old && !record.DV_KEM_THEO_ID
                ? (
                    <Select
                        value={record.TIEP_TAN_ID || data?.NHANSU_ID}
                        dataSource={personnel.map(i => ({ ...i, NAME: getFullName(i) }))}
                        titleKey="NAME"
                        valueKey="NHANSU_ID"
                        filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                        readOnly={disableDichVu}
                        style={{ width: '100%' }}
                        onChange={(val) => handleNguoiTao(val, record)}
                    />
                ) : (
                    <Typography.Text ellipsis={{ tooltip: typeof data === "string" ? data : data?.USERNAME }} className="navy-txt" >
                        {typeof data === "string" ? data : getFullName(data)}
                    </Typography.Text >
                )
        },
        {
            title: i18n.t(languageKeys.field_Ngay_tao),
            dataIndex: "THOI_GIAN_TAO",
            width: "130px",
            render: (data, record) => !record.DV_KEM_THEO_ID
                ? (!!data ? moment(data).format("HH:mm DD/MM/YYYY") : moment().format("HH:mm DD/MM/YYYY"))
                : ""
        },
        {
            title: i18n.t(languageKeys.tiepdon_nguoi_sua),
            dataIndex: "TIEP_TAN_ID",
            showAtNewScreen: false,
            width: 180,
            render: (data, record) => record.old && !record.DV_KEM_THEO_ID
                ? (
                    <Select
                        value={data}
                        dataSource={personnel.map(i => ({ ...i, NAME: getFullName(i) }))}
                        titleKey="NAME"
                        valueKey="NHANSU_ID"
                        filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                        readOnly={disableDichVu}
                        style={{ width: '100%' }}
                        onChange={(val) => handleNguoiTao(val, record)}
                    />
                )
                : <></>,
        },
        {
            title: i18n.t(languageKeys.tiepdon_ngay_sua),
            dataIndex: "THOI_GIAN_SUA",
            showAtNewScreen: false,
            width: "130px",
            render: (data) => !!data && moment(data).format("HH:mm DD/MM/YYYY"),
        },
        {
            title: i18n.t(languageKeys.status_Da_thanh_toan),
            dataIndex: "THANH_TOAN",
            width: 85,
            render: (data) => {
                return <div className={style["test"]}>{!!data ? <Check_box /> : <Uncheckbox />}</div>;
            },
            align: 'center',
        },
        {
            title: i18n.t(languageKeys.dang_TH),
            ellipsis: true,
            dataIndex: "TRANG_THAI_THUC_HIEN",
            width: 90,
            render: (data, record) => {
                if (record.PHAN_LOAI === "KHAM_BENH" || !data) {
                    return <div className={style["test"]}>{dangKham(record.TRANG_THAI) === true || dangKham(record.TRANG_THAI) === 1 ? <Check_box /> : <Uncheckbox />}</div>
                } else {
                    // return <div className={style["test"]}>{data === "DA_THUC_HIEN" ? <Check_box /> : <Uncheckbox />}</div>;
                    return <div className={style["test"]}>{(data === "DA_THUC_HIEN" || data === "DANG_THUC_HIEN") ? <Check_box /> : <Uncheckbox />}</div>;
                }
            },
            align: 'center',
        },
        {
            title: i18n.t(languageKeys.da_KQ),
            ellipsis: true,
            dataIndex: "TRANG_THAI_KET_QUA",
            width: 85,
            nearByFixed: true,
            render: (data) => <div className={style["test"]}>{data === "DA_TRA_KET_QUA" ? <Check_box /> : <Uncheckbox />}</div>,
            align: 'center',
        },
        {
            title: "",
            width: "75px",
            fixed: "right",
            render: (_, record) => (
                !record.DV_KEM_THEO_ID ? (
                    <ActionButton
                        deleteTip={i18n.t(languageKeys.vienphi_xoa_dich_vu)}
                        printTip={i18n.t(languageKeys.tiepdon_in_dich_vu)}
                        record={record}
                        showEdit={false}
                        showDelete={showDeleteBtn(record) && !readOnly}
                        onDelete={handleDelete}
                        showPrint={!readOnly}
                        onPrint={() => onPrint(undefined, [record.DICH_VU_ID || record.DV_ID])}
                        disabledPrint={disableIn || !record.old || readOnly}
                        deleteDisabled={disableDichVu}
                        tooltipInTheoQuyen={!disableIn}
                        tooltipXoaTheoQuyen={!disableDichVu}
                    />
                ) : <></>
            ),
        },
    ];

    // Handle select room
    const handleSelectPhong = (record, dichvu) => {
        const indexKhB = dsDvKhamBenhDaChon.findIndex((item) => item.key === dichvu.key);
        const newObj = {
            ...dichvu,
            PHONG_THUC_HIEN_ID: record.ID,
            BAC_SI: todayDoctors?.find(i => i.PHONG_ID === record.ID),
        }
        if (indexKhB >= 0) {
            setDsDvKhamBenhDaChon((currentArr) => {
                let newArr = [...currentArr]
                newArr[indexKhB] = newObj;
                return newArr;
            });
        } else {
            let index
            let childIndex
            if (dichvu.DV_KEM_THEO_ID) {
                index = dsDvClsDaChon.findIndex((item) => item.DV_ID === dichvu.DV_ID || item.DICH_VU_ID === dichvu.DV_ID);
                childIndex = dsDvClsDaChon[index]?.children?.findIndex((item) => item.key === dichvu.key);
            } else {
                index = dsDvClsDaChon.findIndex((item) => item.key === dichvu.key);
            }
            setDsDvClsDaChon((currentArr) => {
                let newArr = [...currentArr]
                if (dichvu.DV_KEM_THEO_ID) {
                    newArr[index].children[childIndex] = newObj;
                } else {
                    newArr[index] = newObj;
                }
                return newArr;
            });
        }
        forceRender[1](rid());
    };

    //
    const handleSelectNoiThXn = (record, dichvu) => {
        const newObj = {
            ...dichvu,
            NOI_THUC_HIEN_XN: record,
        };
        let index;
        let childIndex;
        if (dichvu.DV_KEM_THEO_ID) {
            index = dsDvClsDaChon.findIndex((item) => item.DV_ID === dichvu.DV_ID || item.DICH_VU_ID === dichvu.DV_ID);
            childIndex = dsDvClsDaChon[index]?.children?.findIndex((item) => item.key === dichvu.key);
        } else {
            index = dsDvClsDaChon.findIndex((item) => item.key === dichvu.key);
        }
        setDsDvClsDaChon((currentArr) => {
            let newArr = [...currentArr];
            if (dichvu.DV_KEM_THEO_ID) {
                let updatedChildren = [...newArr[index].children];
                updatedChildren[childIndex] = newObj;
                newArr[index] = { ...newArr[index], children: updatedChildren };
            } else {
                newArr[index] = newObj;
            }
            return newArr;
        });
        forceRender[1](rid());
    };

    //
    const handleChangeInput = (value, record, key) => {
        //
        const indexKhB = dsDvKhamBenhDaChon.findIndex((item) => item.key === record.key);
        const indexDv = dsDvClsDaChon.findIndex((item) => item.key === record.key);
        //
        const newObj = {
            ...record,
            [key]: value,
        }
        if (indexKhB >= 0) {
            setDsDvKhamBenhDaChon((currentArr) => {
                let newArr = [...currentArr]
                newArr[indexKhB] = newObj;
                return newArr;
            })
        }
        if (indexDv >= 0) {
            setDsDvClsDaChon((currentArr) => {
                let newArr = [...currentArr]
                newArr[indexDv] = newObj;
                return newArr;
            })
        }
    };

    // Hanlde change target
    const handleChangeTarget = (value, record, disable, changeAll = false) => {
        //
        const GIA_DT = (obj) => obj.LIST_GIA_THEO_DICH_VU?.find(i => i.DM_DOI_TUONG_ID === value)?.GIA_DICH_VU
        //
        if (changeAll) {
            setDsDvKhamBenhDaChon(dsDvKhamBenhDaChon.map(dv => ({
                ...dv,
                GIA_DICH_VU: dv.THANH_TOAN ? dv.GIA_DICH_VU : (GIA_DT(dv) ?? dv.GIA_DICH_VU),
                GIA_GOC_DICH_VU: dv.THANH_TOAN ? dv.GIA_DICH_VU : (GIA_DT(dv) ?? dv.GIA_DICH_VU),
                DM_DOI_TUONG_ID: dv.THANH_TOAN ? dv.DM_DOI_TUONG_ID : value
            })))
            setDsDvClsDaChon(dsDvClsDaChon.map(dv => ({
                ...dv,
                GIA_DICH_VU: dv.THANH_TOAN ? dv.GIA_DICH_VU : (GIA_DT(dv) ?? dv.GIA_DICH_VU),
                GIA_GOC_DICH_VU: dv.THANH_TOAN ? dv.GIA_DICH_VU : (GIA_DT(dv) ?? dv.GIA_DICH_VU),
                DM_DOI_TUONG_ID: dv.THANH_TOAN ? dv.DM_DOI_TUONG_ID : value
            })))
            syncTargetRef.current?.setVisible(false)
        } else {
            if (disable) {
                const indexKhB = dsDvKhamBenhDaChon.findIndex((item) => item.key === record.key);
                const indexDv = dsDvClsDaChon.findIndex((item) => item.key === record.key);
                const newObj = {
                    ...record,
                    GIA_DICH_VU: GIA_DT(record) ?? record.GIA_DICH_VU,
                    GIA_GOC_DICH_VU: GIA_DT(record) ?? record.GIA_DICH_VU,
                    DM_DOI_TUONG_ID: value
                }
                if (indexKhB >= 0) {
                    setDsDvKhamBenhDaChon((currentArr) => {
                        let newArr = [...currentArr]
                        newArr[indexKhB] = newObj;
                        return newArr;
                    })
                }
                if (indexDv >= 0) {
                    setDsDvClsDaChon((currentArr) => {
                        let newArr = [...currentArr]
                        newArr[indexDv] = newObj;
                        return newArr;
                    })
                }
            } else {
                syncTargetRef.current?.open({ value, record })
            }
        }
    };

    const tableWidth = columns.reduce((sum, item) => sum + item.width, 0);

    // Handle delete
    const handleDelete = (record) => {
        let filter = (currentArr) => currentArr.filter((item) => item.key !== record.key)
        setDsDvKhamBenhDaChon(filter);
        setDsDvClsDaChon(filter);
    };


    // Chọn người chỉ định
    const handleNguoiTao = (value, record) => {
        const indexKhB = dsDvKhamBenhDaChon.findIndex((item) => item.key === record.key);
        const indexDv = dsDvClsDaChon.findIndex((item) => item.key === record.key);
        const newObj = {
            ...record,
            TIEP_TAN_ID: value,
        }
        if (indexKhB >= 0) {
            setDsDvKhamBenhDaChon((currentArr) => {
                let newArr = [...currentArr]
                newArr[indexKhB] = newObj;
                return newArr;
            })
        }
        if (indexDv >= 0) {
            setDsDvClsDaChon((currentArr) => {
                let newArr = [...currentArr]
                newArr[indexDv] = newObj;
                return newArr;
            })
        }
    };


    // Sửa tổng miễn giảm
    // const handleChangeTotalExemption = (value, totalAll) => {
    //     //
    //     let newDsKhB = dsDvKhamBenhDaChon.map(item => ({
    //         ...item,
    //         MIEN_GIAM_TIEP_DON: value
    //             ? value > 100
    //                 ? Math.round(item.THANH_TIEN * value / totalAll)
    //                 : value
    //             : 0
    //     }))
    //     let newDsCls = dsDvClsDaChon.map(item => ({
    //         ...item,
    //         MIEN_GIAM_TIEP_DON: value
    //             ? value > 100
    //                 ? Math.round(item.THANH_TIEN * value / totalAll)
    //                 : value
    //             : 0
    //     }))
    //     //
    //     const tempTotalExp = calcTotal(newDsKhB.concat(newDsCls), "MIEN_GIAM_TIEP_DON")
    //     if (value > 100 && (tempTotalExp !== value)) {
    //         if (newDsKhB[0]) {
    //             newDsKhB[0] = {
    //                 ...newDsKhB[0],
    //                 MIEN_GIAM_TIEP_DON: newDsKhB[0].MIEN_GIAM_TIEP_DON + tempTotalExp - value
    //             }
    //         } else if (newDsCls[0]) {
    //             newDsCls[0] = {
    //                 ...newDsCls[0],
    //                 MIEN_GIAM_TIEP_DON: newDsCls[0].MIEN_GIAM_TIEP_DON + tempTotalExp - value
    //             }
    //         }
    //     }
    //     setDsDvKhamBenhDaChon(newDsKhB)
    //     setDsDvClsDaChon(newDsCls)
    //     setTotalExp(value)
    // };


    const renderExpandIcon = ({ expanded, onExpand, record }) => {
        if (expanded && record.children) {
            return (
                <CaretDownOutlined
                    onClick={(e) => {
                        setExpandedRow(c => c.filter(r => r !== record.key));
                        e.stopPropagation();
                        onExpand(record, e);
                    }}
                    className={cn(style["icon"], "ant-table-row-expand-icon ant-table-row-expand-icon-expanded")}
                />
            );
        }
        if (!expanded && record.children) {
            return (
                <CaretRightOutlined
                    onClick={(e) => {
                        e.stopPropagation();
                        setExpandedRow(c => [...c, record.key]);
                        onExpand(record, e);
                    }}
                    className={cn(style["icon"], "ant-table-row-expand-icon ant-table-row-expand-icon-expanded")}
                />
            );
        }
    };

    const dataSource = useMemo(() => {
        return dsDvKhamBenhDaChon.concat(dsDvClsDaChon).sort((a, b) => a.order - b.order)
    }, [dsDvKhamBenhDaChon, dsDvClsDaChon])

    // const Summary = () => {
    //     const count = dataSource
    //         .reduce((sum, item) => sum + (item.SO_LUONG ? item.SO_LUONG : 0), 0);
    //     const totalAll = dataSource
    //         .reduce((sum, item) => sum + (
    //             item.SO_LUONG && item.GIA_DICH_VU
    //                 ? item.SO_LUONG * item.GIA_DICH_VU
    //                 : 0
    //         ), 0)
    //     const totalExemp = dataSource.reduce((sum, item) => sum + (
    //         item.MIEN_GIAM_TIEP_DON
    //             ? (item.MIEN_GIAM_TIEP_DON > 100
    //                 ? item.MIEN_GIAM_TIEP_DON
    //                 : (item.GIA_DICH_VU * item.SO_LUONG / 100 * item.MIEN_GIAM_TIEP_DON))
    //             : 0
    //     ), 0)
    //     const totalBillExp = totalExemp + (
    //         totalExp > 100
    //             ? totalExp
    //             : (totalAll - totalExemp) / 100 * totalExp
    //     )
    //     return (
    //         <Table.Summary fixed>
    //             <Table.Summary.Row>
    //                 <Table.Summary.Cell index={0} />
    //                 <Table.Summary.Cell index={1} colSpan={2}>
    //                     <span className="blue-txt">{i18n.t(languageKeys.tong) + ": "}</span>
    //                 </Table.Summary.Cell>
    //                 <Table.Summary.Cell index={2}>
    //                     <span className="blue-txt" style={{ marginLeft: 10 }}>{count}</span>
    //                 </Table.Summary.Cell>
    //                 <Table.Summary.Cell index={3}>
    //                     <span className="blue-txt">{formatNumberToPrice(+(totalAll))}</span>
    //                 </Table.Summary.Cell>
    //                 <Table.Summary.Cell index={4}>
    //                     <span className="blue-txt" style={{ marginLeft: 10 }}>{formatNumberToPrice(totalBillExp)}</span>
    //                 </Table.Summary.Cell>
    //                 <Table.Summary.Cell index={5}>
    //                     <span className="blue-txt">{formatNumberToPrice(+(totalAll - totalBillExp))}</span>
    //                 </Table.Summary.Cell>
    //                 <Table.Summary.Cell index={6} colSpan={10}>
    //                     <InputNumber
    //                         onChange={setTotalExp}
    //                         value={totalExp}
    //                         className={style['exemptions-input']}
    //                         placeholder={i18n.t(languageKeys.common_Nhap)}
    //                         formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //                         addonAfter={totalExp > 100 ? "đ" : "%"}
    //                         size="middle"
    //                         readOnly={readOnly}
    //                         min={0}
    //                         max={totalAll}
    //                     />
    //                 </Table.Summary.Cell>
    //             </Table.Summary.Row>
    //         </Table.Summary>
    //     )
    // }


    const checkDuplicateService = (listDv) => {
        try {
            const getFlat = () => {
                let rs = []
                for (const item of listDv) {
                    rs.push(item);
                    if (item.children?.[0]) {
                        let childrens = item.children.map(i => ({ ...i, DV_ID: i.DV_KEM_THEO_ID }));
                        rs.push(...childrens);
                    }
                }
                return rs
            }
            let cp_list = getFlat();
            let check1 = hasDuplicateValues(cp_list, 'DV_ID');
            return check1.duplicatedItems
        } catch (error) {
            console.log(error);
            return []
        }
    }

    const arrCheck = useMemo(() => {
        try {
            return checkDuplicateService([...dsDvKhamBenhDaChon, ...dsDvClsDaChon]).map(i => i?.DV_ID)
        } catch (error) {
            return [];
        }
    }, [dsDvKhamBenhDaChon, dsDvClsDaChon]);

    const renderClassName = (record) => {
        try {
            if (hightlighter)
                return (arrCheck.includes(record.DV_ID) || arrCheck.includes(record.DV_KEM_THEO_ID)) ? style['red-row'] : ''
        } catch (error) {
            return ''
        }
    }

    return (
        <TableLocal
            columns={isCreateNew ? columns.filter(i => !i.hasOwnProperty('showAtNewScreen')) : columns}
            expandable={{
                expandIcon: renderExpandIcon,
                defaultExpandAllRows: true,
                expandedRowKeys: expandedRow,
            }}
            className={style['table-add']}
            rowKey={"key"}
            scroll={{ x: tableWidth, y: "calc(100vh - 475px)" }}
            classRow
            // classnameRow={renderClassName}
            dataSource={dataSource}
            rowPropsConfig={(record) => ({
                disabled: disableDichVu || !record.old,
            })}
            // summary={() => dataSource[0] && Summary()}
            loading={loading}
        />
    )
}

export default memo(TablekhamDv);

// const PhongThucHien = ({ dichvu = {}, phong = {}, onSelectPhong = () => {}, readOnly = false, scheduleInfo, selectedNguonKhach }) => {
//     const { dsPhong, loadingDsPhong, layDsPhongTheoDichVuLocal } = usePhong();
//     const [visible, setVisible] = useState(false);
//     const exp = useSelector(getExemptionData)

//     useEffect(() => {
//         layDsPhongTheoDichVuLocal(dichvu);
//     }, [dichvu.DV_ID, dichvu.DV_KEM_THEO_ID]);

//     useEffect(() => {
//         if (!dichvu.PHONG_ID && !(dichvu.LUOT_DIEU_TRI_ID && dichvu.DV_KEM_THEO_ID)) {
//             for (let i = 0; i < dsPhong.length; i++) {
//                 const element = dsPhong[i];
//                 // if (isRoomWorking(element)) {
//                 //     handleClickPhong(element);
//                 //     return;
//                 // }
//                 handleClickPhong(element);
//             }
//         }
//     }, [dsPhong]);

//     const columns = [
//         {
//             title: i18n.t(languageKeys.field_Ten_phong),
//             dataIndex: "TEN_KHOA_PHONG",
//         },
//         {
//             title: i18n.t(languageKeys.field_So_phong),
//             dataIndex: "MA_PHONG",
//         },
//         // {
//         //     title: i18n.t(languageKeys.field_Benh_nhan_cho_kham),
//         //     dataIndex: "SO_CHO_KHAM",
//         // },
//         {
//             title: i18n.t(languageKeys.label_Thoi_gian),
//             render: (_, record) => `${!!record?.GIO_BAT_DAU ? record?.GIO_BAT_DAU + " " : ""}-${!!record?.GIO_KET_THUC ? " " + record?.GIO_KET_THUC : ""}`,
//         },
//         {
//             title: i18n.t(languageKeys.field_Trang_thai),
//             render: (_, record) => {
//                 if (!isRoomWorking(record)) {
//                     return <div className="red-txt">{i18n.t(languageKeys.tiepdon_da_nghi)}</div>;
//                 }
//                 return <div className="green-txt">{i18n.t(languageKeys.dang_hoat_dong)}</div>;
//             },
//         },
//     ];

//     const handleClickPhong = (record) => {
//         // if (!isRoomWorking(record)) {
//         //     notification.error({
//         //         message: `${record.TEN_KHOA_PHONG} ` + i18n.t(languageKeys.tiepdon_khong_hoat_dong),
//         //         placement: "bottomLeft",
//         //     });
//         // }
//         // onSelectPhong(record, dichvu);
//         getExemption(record)
//         setVisible(false);
//     };

//     const getExemption = async (record) => {
//         if (selectedNguonKhach) {
//             const EXP = exp.find(i => i.DV_ID === dichvu.DV_ID)
//             onSelectPhong(record, dichvu, EXP ? EXP.MIEN_GIAM : 0)
//         } else {
//             onSelectPhong(record, dichvu);
//         }
//     }

//     /**
//      *
//      * @param {*} readOnlyProps trạng thái từ comp cha
//      * @param {*} appointment cuộc hẹn được chọn
//      * @param {*} dichvu dịch vụ được chọn trên bảng
//      * @returns  kiểm tra khả năng thực hiện chuyển phòng
//      */
//     const checkStatus = (readOnlyProps, appointment, dichvu) => {
//         //HLog(" kiểm tra khả năng dổi phòng , cận lâm sàng :::", appointment, dichvu);
//         let check = readOnlyProps;
//         if (!appointment || !(dichvu && dichvu?.old)) return check; // kiểm tra điều kiện chọn phòng nếu là tạo mới.

//         // kiểm tra chọn phòng điều kiện trạng thái dịch vụ.
//         if (!!dichvu && dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.CHO_KHAM) return check;

//         // kiểm tra chọn phòng điều kiện trạng thái cuộc hẹn.
//         if (
//             appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
//             appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
//             appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH
//         ) {
//             check = true;
//         }
//         return check;
//     };

//     const renderTable = () => {
//         return (
//             <div style={{ backgroundColor: "white", boxShadow: "0 0 10px #00000015" }}>
//                 <Table
//                     dataSource={dsPhong}
//                     loading={loadingDsPhong}
//                     columns={columns}
//                     rowKey="ID"
//                     pagination={false}
//                     onRow={(data) => ({
//                         onClick: () => handleClickPhong(data),
//                     })}
//                 />
//             </div>
//         );
//     };

//     if (checkStatus(readOnly, scheduleInfo, dichvu)) {
//         return <Input readOnly value={phong?.TEN_KHOA_PHONG} size="small" style={{ height: "32px" }} />
//     }

//     return (
//         <Dropdown overlay={renderTable()} trigger="click" visible={visible} onVisibleChange={(bool) => setVisible(bool)}>
//             <Input
//                 readOnly
//                 suffix={<DownOutlined style={{ color: "#999" }} />}
//                 value={phong?.TEN_KHOA_PHONG}
//                 // size="small"
//                 style={!isRoomWorking(phong) ? { borderColor: "red", height: "32px" } : { height: "32px" }}
//             />
//         </Dropdown>
//     );
// };


const NoiThucHienXn = ({ dichvu = {}, noiThucHienXn = {}, listNoiThucHienXn = [], handleSelectNoiThXn = () => {}, readOnly = false, scheduleInfo }) => {
    const [visible, setVisible] = useState(false);

    const columns = [
        {
            title: i18n.t(languageKeys.noi_thuc_hien),
            dataIndex: "TEN_NOI_TH",
        },
    ];

    const checkStatus = (readOnlyProps, appointment, dichvu) => {
        let check = readOnlyProps;
        if (!appointment || !(dichvu && dichvu?.old)) return check;
        if (!!dichvu && dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.CHO_KHAM) return check;
        if (
            appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
            appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
            appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH
        ) {
            check = true;
        }
        return check;
    };

    const renderTable = () => {
        return (
            <div style={{ backgroundColor: "white", boxShadow: "0 0 10px #00000015" }}>
                {/* <Table
                    showHeader={false}
                    dataSource={listNoiThucHienXn}
                    columns={columns}
                    rowKey="DV_XN_NOI_THUC_HIEN_ID"
                    pagination={false}
                    onRow={(data) => ({
                        onClick: () => {
                            setVisible(false)
                            handleSelectNoiThXn(data, dichvu)
                        },
                    })}
                /> */}
            </div>
        );
    };

    if (checkStatus(readOnly, scheduleInfo, dichvu)) {
        return <Input readOnly value={noiThucHienXn?.TEN_NOI_TH} size="small" style={{ height: "32px" }} />
    }

    return (
        <Dropdown overlay={renderTable()} trigger="click" visible={visible} onVisibleChange={(bool) => setVisible(bool)}>
            <Input
                readOnly
                suffix={<DownOutlined style={{ color: "#999" }} />}
                value={noiThucHienXn?.TEN_NOI_TH}
                size="small"
                style={{ height: "32px" }}
            />
        </Dropdown>
    );
};

// const ExemptionsInput = ({ data, record, selectedNguonKhach, handleFillExemption, readOnly }) => {

//     // const isChangeNguonKhachID = form.isFieldTouched(fieldTiepDon.DM_NGUON_KHACH_ID)
//     const [checkCall, setcheckCall] = useState(false)
//     const exp = useSelector(getExemptionData)

//     useEffect(() => {
//         if (record.children && !record.old) getExemption()
//     }, [])

//     useEffect(() => {
//         if (checkCall) {
//             getExemption()
//         } else {
//             setcheckCall(true)
//         }
//     }, [exp])

//     const getExemption = () => {
//         if (selectedNguonKhach) {
//             const EXP = exp.find(i => i.DV_ID === record.DV_ID)
//             handleFillExemption(EXP ? EXP.MIEN_GIAM : 0, record)
//         }
//     }

//     const handleKeyDown = (e) => {
//         if (e.key === "-") {
//             e.preventDefault();
//             return false;
//         }
//         // if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
//         //     e.preventDefault();
//         //     return false;
//         // }
//     };

//     return (
//         <InputNumber
//             onChange={(value) => handleFillExemption(value, record)}
//             value={record?.MIEN_GIAM_TIEP_DON || 0}
//             onKeyDown={handleKeyDown}
//             className={style['exemptions-input']}
//             placeholder={i18n.t(languageKeys.common_Nhap)}
//             formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
//             parser={value => parseFloat(value.replace(/\./g, ''))}
//             addonAfter={record?.MIEN_GIAM_TIEP_DON > 100 ? "đ" : "%"}
//             size="middle"
//             readOnly={readOnly}
//         />
//     )
// }